<template>
  <div class="p-p-0">
    <table class="table table-bordered table-hover table-striped" id="schedule_assistance">
      <tr class="bg-emp-shift-header">
        <td colspan="8">
          <div class="p-d-flex p-jc-between">
            <div>
              <Button
                class="p-button-warning p-button-lg schedule-btn"
                icon="pi pi-arrow-circle-left"
                label="Prev"
                @click="previousWeek"
              />
              <Button
                class="p-button-primary p-button-lg schedule-btn"
                icon="pi pi-refresh"
                label="Refresh"
                @click="loadDetails"
              />
            </div>
            <div>
              <Button
                v-if="toggleType == 'min'"
                class="p-button-primary p-button-lg schedule-btn"
                icon="pi pi-angle-double-up"
                label="Full Schedule"
                @click="toggleSchedule"
              />
              <Button
                v-if="toggleType == 'full'"
                class="p-button-primary p-button-lg schedule-btn"
                icon="pi pi-angle-double-down"
                label="Summarized"
                @click="toggleSchedule"
              />
              <Button
                class="p-button-primary p-button-lg schedule-btn"
                icon="pi pi-envelope"
                label="Email"
                @click="confirmEmailList"
              />
              <Button
                class="p-button-primary p-button-lg schedule-btn"
                icon="pi pi-cog"
                label="Options"
                @click="optionsDialog = true"
              />
              <!-- <Button
                class="p-button-primary p-button-lg schedule-btn"
                icon="pi pi-print"
                label="Print"
              /> -->
              <Button
                class="p-button-success p-button-lg schedule-btn"
                icon="pi pi-save"
                @click="postSchedule"
                label="Save"
              />
              <Button
                class="p-button-warning p-button-lg schedule-btn"
                icon="pi pi-arrow-circle-right"
                @click="nextWeek"
                label="Next"
              />
            </div>
          </div>
        </td>
      </tr>
      <tr id="employee_schedule_days_row" class="top-shift-row">
        <td style="width: 18vw" class="p-p-0 table-schedule-head">
          <i class="fa fa-calendar-o p-ml-1" aria-hidden="true"></i>
          Associates Shift
          <label class="btn btn-danger p-p-0 p-ml-1">
            Week Ending {{ formatDate(weekDates.saturday) }}
          </label>
        </td>
        <td style="width: 11.71vw" class="p-p-1 table-schedule-head">
          <h6 class="p-p-0">
           {{getDayName(weekDates.sunday)}}
            <b class="pull-right p-mt-1">
              <i class="pi pi-clock"></i> {{ getStoreDayeHrs("sunday") }}
            </b>
          </h6>
          {{ formatDate(weekDates.sunday) }}
        </td>
        <td style="width: 11.71vw" class="p-p-1 table-schedule-head">
          <h6 class="p-p-0">
            {{getDayName(weekDates.monday)}}
            <b class="pull-right p-mt-1">
              <i class="pi pi-clock"></i> {{ getStoreDayeHrs("monday") }}
            </b>
          </h6>
          {{ formatDate(weekDates.monday) }}
        </td>
        <td style="width: 11.71vw" class="p-p-1 table-schedule-head">
          <h6 class="p-p-0">
           {{getDayName(weekDates.tuesday)}}
            <b class="pull-right p-mt-1">
              <i class="pi pi-clock"></i>
              {{ getStoreDayeHrs("tuesday") }}
            </b>
          </h6>
          {{ formatDate(weekDates.tuesday) }}
        </td>
        <td style="width: 11.71vw" class="p-p-1 table-schedule-head">
          <h6 class="p-p-0">
            {{getDayName(weekDates.wednesday)}}
            <b class="pull-right p-mt-1">
              <i class="pi pi-clock"></i>
              {{ getStoreDayeHrs("wednesday") }}
            </b>
          </h6>
          {{ formatDate(weekDates.wednesday) }}
        </td>
        <td style="width: 11.71vw" class="p-p-1 table-schedule-head">
          <h6 class="p-p-0">
            {{getDayName(weekDates.thursday)}}
            <b class="pull-right p-mt-1">
              <i class="pi pi-clock"></i>
              {{ getStoreDayeHrs("thursday") }}
            </b>
          </h6>
          {{ formatDate(weekDates.thursday) }}
        </td>
        <td style="width: 11.71vw" class="p-p-1 table-schedule-head">
          <h6 class="p-p-0">
            {{getDayName(weekDates.friday)}}
            <b class="pull-right p-mt-1">
              <i class="pi pi-clock"></i> {{ getStoreDayeHrs("friday") }}
            </b>
          </h6>
          {{ formatDate(weekDates.friday) }}
        </td>
        <td style="width: 11.71vw" class="p-p-1 table-schedule-head">
          <h6 class="p-p-0">
            {{getDayName(weekDates.saturday)}}
            <b class="pull-right p-mt-1">
              <i class="pi pi-clock"></i>
              {{ getStoreDayeHrs("saturday") }}
            </b>
          </h6>
          {{ formatDate(weekDates.saturday) }}
        </td>
      </tr>
      <tr>
        <td class="text-left p-p-1">
          Proj Sales <b> ( ${{ plannedProjectedTotal }} ) </b>
          <span id="total_percentage_calculated" class="float-right">
            $ {{ totalProjectedSales }}
          </span>
        </td>
        <td class="p-p-1 text-center">
          <input
            type="number"
            step=".01"
            placeholder="e.g 10"
            v-model="projSales.sunday"
            class="emp_shift_per"
          />
        </td>
        <td class="p-p-1 text-center">
          <input
            type="number"
            step=".01"
            placeholder="e.g 10"
            v-model="projSales.monday"
            class="emp_shift_per"
          />
        </td>
        <td class="p-p-1 text-center">
          <input
            type="number"
            step=".01"
            placeholder="e.g 10"
            v-model="projSales.tuesday"
            class="emp_shift_per"
          />
        </td>
        <td class="p-p-1 text-center">
          <input
            type="number"
            step=".01"
            placeholder="e.g 10"
            v-model="projSales.wednesday"
            class="emp_shift_per"
          />
        </td>
        <td class="p-p-1 text-center">
          <input
            type="number"
            step=".01"
            placeholder="e.g 10"
            v-model="projSales.thursday"
            class="emp_shift_per"
          />
        </td>
        <td class="p-p-1 text-center">
          <input
            type="number"
            step=".01"
            placeholder="e.g 10"
            v-model="projSales.friday"
            class="emp_shift_per"
          />
        </td>
        <td class="p-p-1 text-center">
          <input
            type="number"
            step=".01"
            placeholder="e.g 10"
            v-model="projSales.saturday"
            class="emp_shift_per"
          />
        </td>
      </tr>
      <tr>
        <td class="text-left p-p-1">Scheduled Production</td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(labourProduction.prodSunday) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(labourProduction.prodMonday) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(labourProduction.prodTuesday) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(labourProduction.prodWednesday) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(labourProduction.prodThursday) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(labourProduction.prodFriday) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(labourProduction.prodSaturday) }}
        </td>
      </tr>
      <tr>
        <td class="text-left p-p-1">
          Proj Labour
          <span class="pull-right">{{ formatAmount(totalProjLabour) }} %</span>
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.sunday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.monday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.tuesday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.wednesday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.thursday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.friday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.saturday) }} %
        </td>
      </tr>
      <tr>
        <td class="text-left p-p-1">
          Rushes
          <span class="pull-right">
            <input
              type="number"
              step=".01"
              placeholder="e.g 10"
              v-model="projSales.rushCapacity"
              style="width: 90px"
              class="emp_shift_per"
            />%
          </span>
        </td>
        <td class="p-p-1 text-center">$ {{ formatAmount(expressAmt[0]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(expressAmt[1]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(expressAmt[2]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(expressAmt[3]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(expressAmt[4]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(expressAmt[5]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(expressAmt[6]) }}</td>
      </tr>
      <tr>
        <td class="text-left p-p-1">On the Rack</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(actualAmt[0]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(actualAmt[1]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(actualAmt[2]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(actualAmt[3]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(actualAmt[4]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(actualAmt[5]) }}</td>
        <td class="p-p-1 text-center">$ {{ formatAmount(actualAmt[6]) }}</td>
      </tr>
      <tr>
        <td class="text-left p-p-1">Total Actual Production</td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(totalActualProd[0]) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(totalActualProd[1]) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(totalActualProd[2]) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(totalActualProd[3]) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(totalActualProd[4]) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(totalActualProd[5]) }}
        </td>
        <td class="p-p-1 text-center">
          $ {{ formatAmount(totalActualProd[6]) }}
        </td>
      </tr>
      <tr style="border-bottom: 2px solid #777">
        <td class="text-left p-p-1">
          Actual Labour
          <span
            :class="{
              enableAlertLabel: totalActualLabour > 39,
              enableNormalLabel: totalActualLabour <= 39,
            }"
            class="pull-right"
          >
            {{ formatAmount(totalActualLabour) }}%
          </span>
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.actualLabourSunday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.actualLabourMonday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.actualLabourTuesday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.actualLabourWednesday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.actualLabourThursday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.actualLabourFriday) }} %
        </td>
        <td class="p-p-1 text-center">
          {{ formatAmount(labourProduction.actualLabourSaturday) }} %
        </td>
      </tr>
      <template
        v-for="(associates, index) in getMapedSchedule"
        :key="associates"
      >
        <tr v-if="currentBranch == associates.code || toggleType == 'full'">
          <td
            scope="row"
            style="border-right: 2px solid #777"
            class="hoverable first-col-shift p-1"
          >
            <div class="gray-color m-0">
              <span class="shift-paycode"> Full Time </span>
              <span class="pull-right">
                <input
                  type="checkbox"
                  style="width: 25px; height: 25px"
                  class="associate_schedule_checkbox"
                  v-model="chosenAssociatesList"
                  :value="associates.employeeEmail"
                />
              </span>
            </div>
            <h5 class="gray-color p-mt-3">
              <span
                class="customer-profile-link"
                @click="openAssociateProfile(associates.employeeId)"
                >{{ associates.employeeFirstname }}
                {{ associates.employeeName }}</span
              >
              <span class="pull-right schedule-details">
                ({{ associates.employeeId }})
              </span>
            </h5>
            <span class="gray-color pull-right">
              <small
                ><b>{{ associates.jobClass }}</b></small
              >
            </span>
            <div>
              <span class="schedule-details">
                Magic ${{ formatAmount(associates.magic) }}
              </span>
            </div>
            <div>
              <p class="schedule-details p-p-0 p-m-0">
                Store
                <span class="pull-right">
                  {{ getAssociateStoreHrs(associates.employeeId,associates.code) }}
                </span>
              </p>
            </div>
            <div>
              <span>
                <p class="schedule-details p-p-0 p-m-0">
                  Total
                  <span class="pull-right">
                    {{ getAssociateHrs(associates.employeeId) }}
                  </span>
                </p>
              </span>
              <p>
                <small class="pull-right">
                  <b
                    class="schedule-details"
                    :class="{ currentStore: currentBranch == associates.code }"
                  >
                    {{ associates.mall }}
                  </b>
                </small>
              </p>
            </div>
          </td>
          <td class="text-center">
            <template v-for="(day, idx) in associates.sunday" :key="day">
              <div
                class="slot-style"
                @click="
                  day.approveStatus != 'Yes'
                    ? updateShiftDialog(
                        associates,
                        weekDates.sunday,
                        'sunday',
                        index,
                        idx
                      )
                    : ''
                "
              >
                <label class="p-p-0 p-m-0">
                  {{ formatTime(day.timeIn) }} - {{ formatTime(day.timeOut) }}
                </label>
                <p
                  class="shift-paycode p-m-0"
                  :class="{ overTime: day.paycode == 'Overtime' }"
                >
                  {{ day.paycode }}
                </p>
                <p class="p-m-0 notes-height">
                  <small class="breakTimings" v-if="day.breakTimings != ''">
                    Break : {{ day.breakTimings }}
                  </small>
                </p>
                <p class="p-m-0 notes-height">
                  <small> {{ day.notes }} </small>
                </p>
                <p>
                  <i class="pi pi-clock"></i>
                  {{ getTimeDiff(day.timeOut, day.timeIn, day.breakTimings) }}
                </p>
              </div>
            </template>
            <Button
              v-if="currentBranch == associates.code"
              class="p-button-primary btn-sm btn-block"
              icon="pi pi-plus-circle"
              label="Add"
              @click="
                openShiftDialog(associates, weekDates.sunday, 'sunday', index)
              "
            />
          </td>
          <td class="text-center">
            <template v-for="(day, idx) in associates.monday" :key="day">
              <div
                class="slot-style"
                @click="
                  day.approveStatus != 'Yes'
                    ? updateShiftDialog(
                        associates,
                        weekDates.monday,
                        'monday',
                        index,
                        idx
                      )
                    : ''
                "
              >
                <label class="p-p-0 p-m-0">
                  {{ formatTime(day.timeIn) }} - {{ formatTime(day.timeOut) }}
                </label>
                <p
                  class="shift-paycode p-m-0"
                  :class="{ overTime: day.paycode == 'Overtime' }"
                >
                  {{ day.paycode }}
                </p>
                <p class="p-m-0 notes-height">
                  <small class="breakTimings" v-if="day.breakTimings != ''">
                    Break : {{ day.breakTimings }}
                  </small>
                </p>
                <p class="p-m-0 notes-height">
                  <small> {{ day.notes }} </small>
                </p>
                <p>
                  <i class="pi pi-clock"></i>
                  {{ getTimeDiff(day.timeOut, day.timeIn, day.breakTimings) }}
                </p>
              </div>
            </template>
            <Button
              v-if="currentBranch == associates.code"
              class="p-button-primary btn-sm btn-block"
              icon="pi pi-plus-circle"
              label="Add"
              @click="
                openShiftDialog(associates, weekDates.monday, 'monday', index)
              "
            />
          </td>
          <td class="text-center">
            <template v-for="(day, idx) in associates.tuesday" :key="day">
              <div
                class="slot-style"
                @click="
                  day.approveStatus != 'Yes'
                    ? updateShiftDialog(
                        associates,
                        weekDates.tuesday,
                        'tuesday',
                        index,
                        idx
                      )
                    : ''
                "
              >
                <label class="p-p-0 p-m-0">
                  {{ formatTime(day.timeIn) }} - {{ formatTime(day.timeOut) }}
                </label>
                <p
                  class="shift-paycode p-m-0"
                  :class="{ overTime: day.paycode == 'Overtime' }"
                >
                  {{ day.paycode }}
                </p>
                <p class="p-m-0 notes-height">
                  <small class="breakTimings" v-if="day.breakTimings != ''">
                    Break : {{ day.breakTimings }}
                  </small>
                </p>
                <p class="p-m-0 notes-height">
                  <small> {{ day.notes }} </small>
                </p>
                <p>
                  <i class="pi pi-clock"></i>
                  {{ getTimeDiff(day.timeOut, day.timeIn, day.breakTimings) }}
                </p>
              </div>
            </template>
            <Button
              v-if="currentBranch == associates.code"
              class="p-button-primary btn-sm btn-block"
              icon="pi pi-plus-circle"
              label="Add"
              @click="
                openShiftDialog(associates, weekDates.tuesday, 'tuesday', index)
              "
            />
          </td>
          <td class="text-center">
            <template v-for="(day, idx) in associates.wednesday" :key="day">
              <div
                class="slot-style"
                @click="
                  day.approveStatus != 'Yes'
                    ? updateShiftDialog(
                        associates,
                        weekDates.wednesday,
                        'wednesday',
                        index,
                        idx
                      )
                    : ''
                "
              >
                <label class="p-p-0 p-m-0">
                  {{ formatTime(day.timeIn) }} - {{ formatTime(day.timeOut) }}
                </label>
                <p
                  class="shift-paycode p-m-0"
                  :class="{ overTime: day.paycode == 'Overtime' }"
                >
                  {{ day.paycode }}
                </p>
                <p class="p-m-0 notes-height">
                  <small class="breakTimings" v-if="day.breakTimings != ''">
                    Break : {{ day.breakTimings }}
                  </small>
                </p>
                <p class="p-m-0 notes-height">
                  <small> {{ day.notes }} </small>
                </p>
                <p>
                  <i class="pi pi-clock"></i>
                  {{ getTimeDiff(day.timeOut, day.timeIn, day.breakTimings) }}
                </p>
              </div>
            </template>
            <Button
              v-if="currentBranch == associates.code"
              class="p-button-primary btn-sm btn-block"
              icon="pi pi-plus-circle"
              label="Add"
              @click="
                openShiftDialog(
                  associates,
                  weekDates.wednesday,
                  'wednesday',
                  index
                )
              "
            />
          </td>
          <td class="text-center">
            <template v-for="(day, idx) in associates.thursday" :key="day">
              <div
                class="slot-style"
                @click="
                  day.approveStatus != 'Yes'
                    ? updateShiftDialog(
                        associates,
                        weekDates.thursday,
                        'thursday',
                        index,
                        idx
                      )
                    : ''
                "
              >
                <label class="p-p-0 p-m-0">
                  {{ formatTime(day.timeIn) }} - {{ formatTime(day.timeOut) }}
                </label>
                <p
                  class="shift-paycode p-m-0"
                  :class="{ overTime: day.paycode == 'Overtime' }"
                >
                  {{ day.paycode }}
                </p>
                <p class="p-m-0 notes-height">
                  <small class="breakTimings" v-if="day.breakTimings != ''">
                    Break : {{ day.breakTimings }}
                  </small>
                </p>
                <p class="p-m-0 notes-height">
                  <small> {{ day.notes }} </small>
                </p>
                <p>
                  <i class="pi pi-clock"></i>
                  {{ getTimeDiff(day.timeOut, day.timeIn, day.breakTimings) }}
                </p>
              </div>
            </template>
            <Button
              v-if="currentBranch == associates.code"
              class="p-button-primary btn-sm btn-block"
              icon="pi pi-plus-circle"
              label="Add"
              @click="
                openShiftDialog(
                  associates,
                  weekDates.thursday,
                  'thursday',
                  index
                )
              "
            />
          </td>
          <td class="text-center">
            <template v-for="(day, idx) in associates.friday" :key="day">
              <div
                class="slot-style"
                @click="
                  day.approveStatus != 'Yes'
                    ? updateShiftDialog(
                        associates,
                        weekDates.friday,
                        'friday',
                        index,
                        idx
                      )
                    : ''
                "
              >
                <label class="p-p-0 p-m-0">
                  {{ formatTime(day.timeIn) }} - {{ formatTime(day.timeOut) }}
                </label>
                <p
                  class="shift-paycode p-m-0"
                  :class="{ overTime: day.paycode == 'Overtime' }"
                >
                  {{ day.paycode }}
                </p>
                <p class="p-m-0 notes-height">
                  <small class="breakTimings" v-if="day.breakTimings != ''">
                    Break : {{ day.breakTimings }}
                  </small>
                </p>
                <p class="p-m-0 notes-height">
                  <small> {{ day.notes }} </small>
                </p>
                <p>
                  <i class="pi pi-clock"></i>
                  {{ getTimeDiff(day.timeOut, day.timeIn, day.breakTimings) }}
                </p>
              </div>
            </template>
            <Button
              v-if="currentBranch == associates.code"
              class="p-button-primary btn-sm btn-block"
              icon="pi pi-plus-circle"
              label="Add"
              @click="
                openShiftDialog(associates, weekDates.friday, 'friday', index)
              "
            />
          </td>
          <td class="text-center">
            <template v-for="(day, idx) in associates.saturday" :key="day">
              <div
                class="slot-style"
                @click="
                  day.approveStatus != 'Yes'
                    ? updateShiftDialog(
                        associates,
                        weekDates.saturday,
                        'saturday',
                        index,
                        idx
                      )
                    : ''
                "
              >
                <label class="p-p-0 p-m-0">
                  {{ formatTime(day.timeIn) }} - {{ formatTime(day.timeOut) }}
                </label>
                <p
                  class="shift-paycode p-m-0"
                  :class="{ overTime: day.paycode == 'Overtime' }"
                >
                  {{ day.paycode }}
                </p>
                <p class="p-m-0 notes-height">
                  <small class="breakTimings" v-if="day.breakTimings != ''">
                    Break : {{ day.breakTimings }}
                  </small>
                </p>
                <p class="p-m-0 notes-height">
                  <small> {{ day.notes }} </small>
                </p>
                <p>
                  <i class="pi pi-clock"></i>
                  {{ getTimeDiff(day.timeOut, day.timeIn, day.breakTimings) }}
                </p>
              </div>
            </template>
            <Button
              v-if="currentBranch == associates.code"
              class="p-button-primary btn-sm btn-block"
              icon="pi pi-plus-circle"
              label="Add"
              @click="
                openShiftDialog(
                  associates,
                  weekDates.saturday,
                  'saturday',
                  index
                )
              "
            />
          </td>
        </tr>
      </template>
    </table>
  </div>

  <Dialog
    v-model:visible="shiftDialog"
    :style="{ width: '70vw' }"
    :maximizable="true"
    position="center"
    class="p-fluid"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        {{ dialogTitle }}
      </h5>
    </template>
    <div class="p-d-flex p-jc-between p-mb-3">
      <div class="p-mr-2">
        <b
          >Associate Name : {{ activeAssociate.employeeFirstName }}
          {{ activeAssociate.employeeLastName }} ({{
            activeAssociate.employeeId
          }})</b
        >
      </div>
      <div class="p-mr-2">
        <b
          >Date : {{ formatDate(activeDate) }} | Store Manager : {{ associate }}
        </b>
      </div>
    </div>
    <hr />
    <div class="p-d-flex p-jc-between p-mb-3">
      <div class="p-mr-2">
        <div class="p-fluid p-mt-3">
          <h5>Time In</h5>
          <input type="time" class="form-control time-field" v-model="timeIn" />
        </div>
      </div>
      <div class="p-mr-2">
        <div class="p-fluid p-mt-3">
          <h5>Time Out</h5>
          <input
            type="time"
            class="form-control time-field"
            v-model="timeOut"
          />
        </div>
      </div>
    </div>
    <div class="p-field">
      <label>Shift Pay code</label>
      <Dropdown
        v-model="selectedPaycode"
        :options="paycodeList"
        optionLabel="name"
        placeholder="Search Pay code"
        :showClear="true"
      />
    </div>
    <div class="p-field">
      <label>Shift Notes</label>
      <InputText placeholder="Shift notes" v-model="notes" />
    </div>
    <div class="p-d-flex p-jc-between p-mb-3">
      <div class="p-fluid">
        <div class="p-field">
          <label class="add-to-capacity">
            <input
              type="checkbox"
              style="width: 30px; height: 30px"
              v-model="capacity"
            />
            Include this to Capacity Chart
          </label>
        </div>
      </div>
      <div class="p-fluid p-mt-3" v-if="actionType == 'edit'">
        <div class="p-field">
          <Button
            label="Delete Shift"
            class="p-button-danger"
            icon="pi pi-times"
            @click="deleteShift"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        v-if="actionType == 'add'"
        type="submit"
        label="Save Shift"
        icon="pi pi-check"
        class="p-button-primary"
        @click="saveShift"
      />
    </template>
  </Dialog>

  <Dialog
    v-model:visible="deleteShiftDialog"
    :style="{ width: '50vw' }"
    :maximizable="true"
    position="center"
    class="p-fluid"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        {{ dialogTitle }}
      </h5>
    </template>
    <div class="p-d-flex p-jc-between p-mb-3">
      <div class="p-mr-2">
        <b
          >Associate Name : {{ activeAssociate.employeeFirstName }}
          {{ activeAssociate.employeeLastName }} ({{
            activeAssociate.employeeId
          }})</b
        >
      </div>
      <div class="p-mr-2">
        <b
          >Date : {{ formatDate(activeDate) }} | Store Manager : {{ associate }}
        </b>
      </div>
    </div>
    <hr />
    <div class="p-mr-2">
      <p style="background-color: green; color: #fff; padding: 3px">
        WARNING : It will erase all the overtime paycodes for this week
      </p>
    </div>
    <hr />
    <div class="p-fluid p-mt-3">
      <div class="p-field">
        <Button
          label="Delete Shift"
          class="p-button-danger"
          icon="pi pi-times"
          @click="deleteShift"
        />
      </div>
    </div>
  </Dialog>

   <Dialog
    v-model:visible="emailScheduleDialog"
    :style="{ width: '650px' }"
    header="Confirm"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span
        >Are you sure to mail Associate Schedule ?</span
      >
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="emailScheduleDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-danger"
        @click="screenShot()"
      />
    </template>
  </Dialog>

  <ScheduleOptions
    v-on:emitScheduleEvent="closeOptionDialog"
    :receiptDetail="{ optionsDialog: optionsDialog }"
  />
  <AssociateDialog
    :associateDetail="{
      status: associateStatus,
      employeeId: employeeId,
      permission: permission,
      title: dialogTitle,
    }"
    v-on:updateAssociateStatus="updateAssociateStatus"
  />

  <AssociatePinVerification
    :receiptDetail="{ associateType: verificationType }"
    v-on:emitAssociatePinEvent="closePINDialog"
  />
</template>
<script lang="ts">
import { Options, mixins } from "vue-class-component";
import Schedule from "../../service/Schedule";
import ScheduleOptions from "../../components/ScheduleOptions.vue";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { camelCase } from "lodash";
import {
  AssociateList,
  ScheduleList,
  MapList,
  ISavedHoursList
} from "../hoursApproval/IHoursAppoval";

import AssociateDialog from "../../components/AssociateDialog.vue";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";
import SAMixin from "../../mixins/SAMixin";
import UtilityOptions from "../../mixins/UtilityOptions";
import domtoimage from "dom-to-image-more";

@Options({
  components: { ScheduleOptions, AssociatePinVerification, AssociateDialog },
  title: "Schedule Assistance",
})
export default class ScheduleAssistance extends mixins(
  SAMixin,
  UtilityOptions
) {
  private schTxn;
  private toast;
  private approvedHoursList:ISavedHoursList [] = [];
  private chosenAssociatesList = [];
  private deleteShiftDialog = false;
  private emailScheduleDialog = false;
  private optionsDialog = false;
  private shiftDialog = false;
  private dialogTitle = "Add Associate Shift";
  private toggleType = "min";
  private actionType = "add";
  private activeDate = "";
  private activeDayName = "";
  private verificationType = "manager";
  private activeAssociate = {
    employeeId: "",
    employeeFirstName: "",
    employeeLastName: "",
    employeeMagic: 0,
    employeeHourlyRate: 0,
    employeeJobClass: "",
    employeeBranch1: "",
    employeeBranch2: "",
    employeeBranch3: "",
    index: 0,
    shiftIndex: 0,
  };
  private timeIn = "10:00";
  private timeOut = "18:00";
  private notes = "";
  private capacity = true;
  private selectedPaycode = {
    name: "Regular",
  };

  private currentBranch = "";
  private associate = "";
  private weekPercentages = [0, 0, 0, 0, 0, 0, 0, 0];

  private weekDates = {
    currentSunday: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  };

  private projSales = {
    sunday: 0,
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    rushCapacity: 0,
    weekNo: "",
    weekDate: "",
  };

  private actualAmt = [0, 0, 0, 0, 0, 0, 0];
  private expressAmt = [0, 0, 0, 0, 0, 0, 0];

  private associateList: AssociateList[] = [];
  private scheduleList: ScheduleList[] = [];
  private mapList: MapList[] = [];
  private currentDate = "";
  private nextDate = "";
  private weekReceiptTotal = [0, 0, 0, 0, 0, 0, 0];
  private previousDate = "";
  private plannedProjectedTotal = 0;
  private weeklyHrs = 0;
  private dailyHrs = 0;
  private overtimeRate = 0;
  private statHolidayRate = 0;
  private employeeId = "";
  private associateStatus = "";
  private permission = "";
  private storeBreakHours = 0;

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.schTxn = new Schedule();
    this.toast = new Toaster();
  }

  mounted() {
    this.loadDetails();
  }

  loadDetails() {
    this.associateList = [];
    this.mapList = [];

    this.schTxn.getScheduleDetails(this.currentDate).then((data) => {
      const res = this.camelizeKeys(data);
      this.scheduleList = res.shiftList;
      this.weekDates = res.weekDates;
      this.plannedProjectedTotal = res.plannedProjectedTotal;
      this.projSales.sunday = res.weekPercentage.sunday;
      this.projSales.monday = res.weekPercentage.monday;
      this.projSales.tuesday = res.weekPercentage.tuesday;
      this.projSales.wednesday = res.weekPercentage.wednesday;
      this.projSales.thursday = res.weekPercentage.thursday;
      this.projSales.friday = res.weekPercentage.friday;
      this.projSales.saturday = res.weekPercentage.saturday;
      this.projSales.rushCapacity = res.weekPercentage.rushCapacity;
      this.projSales.weekNo = res.weekPercentage.weekNo;
      this.projSales.weekDate = res.weekPercentage.weekDate;

      this.actualAmt = res.actualAmount;
      this.expressAmt = res.expressOrders;
      
      this.associateList = res.employeeList;
      this.currentBranch = res.currentBranch;
      this.weeklyHrs = res.weeklyHrs;
      this.dailyHrs = res.dailyHrs;
      this.storeBreakHours = Number(res.breakHrs);
      this.overtimeRate = res.overtimeRate;
      this.statHolidayRate = res.statHolidayRate;
      this.projSales.weekDate = res.weekDates.saturday;
      this.projSales.weekNo = this.getWeekNo(res.weekDates.saturday);
      this.currentDate = res.weekDates.saturday;
      this.nextDate = res.nextWeek;
      this.weekReceiptTotal = res.weekReceiptTotal;
      this.previousDate = res.previousWeek;
      this.approvedHoursList = res.storeHoursList;
      this.mapSchedule();
    });
  }

  openAssociateProfile(id) {
    this.dialogTitle = "Associate Profile";
    this.employeeId = id;
    this.permission = "no";
    this.associateStatus = "updateAssociate";
  }

  updateAssociateStatus() {
    this.associateStatus = "";
  }

  mapSchedule() {
    this.mapList = [];
    this.associateList.forEach((e) => {
      const sundayList = this.filterAssociateShift(
        e.employeeId,
        this.weekDates.sunday,
        e.code
      );

      const mondayList = this.filterAssociateShift(
        e.employeeId,
        this.weekDates.monday,
        e.code
      );

      const tuesdayList = this.filterAssociateShift(
        e.employeeId,
        this.weekDates.tuesday,
        e.code
      );

      const wednesdayList = this.filterAssociateShift(
        e.employeeId,
        this.weekDates.wednesday,
        e.code
      );

      const thursdayList = this.filterAssociateShift(
        e.employeeId,
        this.weekDates.thursday,
        e.code
      );

      const fridayList = this.filterAssociateShift(
        e.employeeId,
        this.weekDates.friday,
        e.code
      );

      const saturdayList = this.filterAssociateShift(
        e.employeeId,
        this.weekDates.saturday,
        e.code
      );

      const map: MapList = {
        employeeEmail: e.employeeEmail,
        employeeFirstname: e.employeeFirstname,
        employeeId: e.employeeId,
        employeeName: e.employeeName,
        employeeType: e.employeeType,
        jobClass: e.jobClass,
        magic: e.magic,
        hourlyRate: e.hourlyRate,
        branchId: e.branchId,
        branchId2: e.branchId2,
        branchId3: e.branchId3,
        code: e.code,
        mall: e.mall,
        sunday: sundayList,
        monday: mondayList,
        tuesday: tuesdayList,
        wednesday: wednesdayList,
        thursday: thursdayList,
        friday: fridayList,
        saturday: saturdayList,
      };

      this.mapList.push(map);
    });
  }

  get getMapedSchedule() {
     
     this.mapList.map(e => {
       e.sunday.sort((a, b) => moment(a.timeIn, "HH:mm").isBefore( moment(b.timeIn, "HH:mm")) ? -1 : 1);
       e.monday.sort((a, b) => moment(a.timeIn, "HH:mm").isBefore( moment(b.timeIn, "HH:mm")) ? -1 : 1);
       e.tuesday.sort((a, b) => moment(a.timeIn, "HH:mm").isBefore( moment(b.timeIn, "HH:mm")) ? -1 : 1);
       e.wednesday.sort((a, b) => moment(a.timeIn, "HH:mm").isBefore( moment(b.timeIn, "HH:mm")) ? -1 : 1);
       e.thursday.sort((a, b) => moment(a.timeIn, "HH:mm").isBefore( moment(b.timeIn, "HH:mm")) ? -1 : 1);
       e.friday.sort((a, b) => moment(a.timeIn, "HH:mm").isBefore( moment(b.timeIn, "HH:mm")) ? -1 : 1);
       e.saturday.sort((a, b) => moment(a.timeIn, "HH:mm").isBefore( moment(b.timeIn, "HH:mm")) ? -1 : 1);
     });

    return this.mapList;
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  filterAssociateShift(empID, dayDate, code) {
    const schedule: ScheduleList[] = [];

    this.scheduleList.forEach((e) => {
      if (
        e.employeeId == empID &&
        e.shiftDate == dayDate &&
        e.branchId == code
      ) {
        schedule.push(e);
      }
    });

    return schedule;
  }

  openShiftDialog(emp, dayDate, dayName, index) {
    this.clearDetails();

    this.shiftDialog = true;
    this.dialogTitle = "Add Associate Shift";
    this.actionType = "add";
    this.activeAssociate.employeeId = emp.employeeId;
    this.activeAssociate.employeeFirstName = emp.employeeFirstname;
    this.activeAssociate.employeeLastName = emp.employeeName;
    this.activeAssociate.employeeMagic = emp.magic;
    this.activeAssociate.employeeHourlyRate = emp.hourlyRate;
    this.activeAssociate.employeeJobClass = emp.jobClass;
    this.activeAssociate.employeeBranch1 = emp.branchId;
    this.activeAssociate.employeeBranch2 = emp.branchId2;
    this.activeAssociate.employeeBranch3 = emp.branchId3;

    this.activeAssociate.index = index;
    this.activeDate = dayDate;
    this.activeDayName = dayName;
  }

  updateShiftDialog(emp, dayDate, dayName, index, idx) {
    if (emp.code == this.currentBranch) {
      this.deleteShiftDialog = true;
      this.dialogTitle = "Delete Associate Shift";
      this.activeAssociate.employeeId = emp.employeeId;
      this.activeAssociate.employeeFirstName = emp.employeeFirstname;
      this.activeAssociate.employeeLastName = emp.employeeName;
      this.activeAssociate.employeeMagic = emp.magic;
      this.activeAssociate.employeeHourlyRate = emp.hourlyRate;
      this.activeAssociate.employeeJobClass = emp.jobClass;
      this.activeAssociate.employeeBranch1 = emp.branchId;
      this.activeAssociate.employeeBranch2 = emp.branchId2;
      this.activeAssociate.employeeBranch3 = emp.branchId3;
      this.activeAssociate.index = index;
      this.activeAssociate.shiftIndex = idx;
      this.activeDate = dayDate;
      this.activeDayName = dayName;
    } else {
      this.toast.showWarning(
        "Sorry only created store manager can modify the shift"
      );
    }
  }

  saveShift() {
    const t = this.checkTimeValid(this.timeIn, this.timeOut);
    const d = moment(this.activeDate, "YYYY-MM-DD");
    const dayName = d.format("dddd");

    if (t == false) {
      this.toast.showWarning("Please choose valid time in and out");
    } else {
      const checkTimenAlocated = this.checkTimeAlocated(
        this.timeIn,
        this.timeOut,
        this.activeAssociate.employeeId,
        this.activeDayName
      );
      if (checkTimenAlocated == "available") {
        let capacity = "no";
        if (this.capacity == true) {
          capacity = "yes";
        } else {
          capacity = "no";
        }

        if (
          this.selectedPaycode.name != "Regular" &&
          this.selectedPaycode.name != "Overtime"
        ) {
          const current = this.currentTimeDiff(this.timeOut, this.timeIn);

          const ExistDailyHrs = this.totalDailyRegularHrs(
            this.activeAssociate.employeeId,
            dayName,
            "Stat Holiday"
          );

          const NetDailyHrs = this.sumTwoTimes(current, ExistDailyHrs);

          const schDetail: ScheduleList = {
            counterId: 0,
            capacityChk: capacity,
            brManagerId: this.associate,
            employeeId: this.activeAssociate.employeeId,
            employeeFirstName: this.activeAssociate.employeeFirstName,
            employeeLastName: this.activeAssociate.employeeLastName,
            employeeMagic: this.activeAssociate.employeeMagic,
            employeeHourlyRate: this.activeAssociate.employeeHourlyRate,
            employeeJobClass: this.activeAssociate.employeeJobClass,
            employeeBranch1: this.activeAssociate.employeeBranch1,
            employeeBranch2: this.activeAssociate.employeeBranch2,
            employeeBranch3: this.activeAssociate.employeeBranch3,
            shiftWeek: this.getWeekNo(this.activeDate),
            shiftDate: this.activeDate,
            timeIn: this.timeIn,
            timeOut: this.timeOut,
            notes: this.notes,
            branchId: this.currentBranch,
            paycode: this.selectedPaycode.name,
            sendMail: "",
            totalLoggedHrs: 0,
            loggedTimings: "",
            breakTimings: '',
          };

          this.insertTimings(schDetail);

          if (this.selectedPaycode.name == "Stat Holiday") {
            if (
              NetDailyHrs > this.dailyHrs &&
              ExistDailyHrs[0] < this.dailyHrs
            ) {
              //WEEK HOURS IS FINE BUT DAILY HOUR IS OVERTIME
              const newTimeOut = this.newTimeOut(
                ExistDailyHrs,
                this.dailyHrs,
                this.timeIn
              );

              const schDetailOverTime: ScheduleList = {
                counterId: 0,
                capacityChk: capacity,
                brManagerId: this.associate,
                employeeId: this.activeAssociate.employeeId,
                employeeFirstName: this.activeAssociate.employeeFirstName,
                employeeLastName: this.activeAssociate.employeeLastName,
                employeeMagic: this.activeAssociate.employeeMagic,
                employeeHourlyRate: this.activeAssociate.employeeHourlyRate,
                employeeJobClass: this.activeAssociate.employeeJobClass,
                employeeBranch1: this.activeAssociate.employeeBranch1,
                employeeBranch2: this.activeAssociate.employeeBranch2,
                employeeBranch3: this.activeAssociate.employeeBranch3,
                shiftWeek: this.getWeekNo(this.activeDate),
                shiftDate: this.activeDate,
                timeIn: newTimeOut,
                timeOut: this.timeOut,
                notes: this.notes,
                branchId: this.currentBranch,
                paycode: "Overtime",
                sendMail: "",
                totalLoggedHrs: 0,
                loggedTimings: "",
                breakTimings: '',
              };

              this.insertTimings(schDetailOverTime);
            }
          }
        } else {
          //REGULAR OR OVERTIME
          const current = this.currentTimeDiff(this.timeOut, this.timeIn);
          const WeekHrs = this.totalWeekRegularHrs(
            this.activeAssociate.employeeId
          );

          const ExistDailyHrs = this.totalDailyRegularHrs(
            this.activeAssociate.employeeId,
            dayName,
            "Regular"
          );

          const NetWeekHrs = this.sumTwoTimes(current, WeekHrs);
          const NetDailyHrs = this.sumTwoTimes(current, ExistDailyHrs);
          const totalRegWeekHrs = WeekHrs[0];

          if (NetWeekHrs <= this.weeklyHrs && NetDailyHrs <= this.dailyHrs) {
            const schDetail: ScheduleList = {
              counterId: 0,
              capacityChk: capacity,
              brManagerId: this.associate,
              employeeId: this.activeAssociate.employeeId,
              employeeFirstName: this.activeAssociate.employeeFirstName,
              employeeLastName: this.activeAssociate.employeeLastName,
              employeeMagic: this.activeAssociate.employeeMagic,
              employeeHourlyRate: this.activeAssociate.employeeHourlyRate,
              employeeJobClass: this.activeAssociate.employeeJobClass,
              employeeBranch1: this.activeAssociate.employeeBranch1,
              employeeBranch2: this.activeAssociate.employeeBranch2,
              employeeBranch3: this.activeAssociate.employeeBranch3,
              shiftWeek: this.getWeekNo(this.activeDate),
              shiftDate: this.activeDate,
              timeIn: this.timeIn,
              timeOut: this.timeOut,
              notes: this.notes,
              branchId: this.currentBranch,
              paycode: "Regular",
              sendMail: "",
              totalLoggedHrs: 0,
              loggedTimings: "",
              breakTimings: '',
            };

            this.insertTimings(schDetail);
          } else if (
            NetWeekHrs <= this.weeklyHrs &&
            NetDailyHrs > this.dailyHrs &&
            ExistDailyHrs[0] < this.dailyHrs
          ) {
            //WEEK HOURS IS FINE BUT DAILY HOUR IS OVERTIME
            const newTimeOut = this.newTimeOut(
              ExistDailyHrs,
              this.dailyHrs,
              this.timeIn
            );

            const schDetailRegular: ScheduleList = {
              counterId: 0,
              capacityChk: capacity,
              brManagerId: this.associate,
              employeeId: this.activeAssociate.employeeId,
              employeeFirstName: this.activeAssociate.employeeFirstName,
              employeeLastName: this.activeAssociate.employeeLastName,
              employeeMagic: this.activeAssociate.employeeMagic,
              employeeHourlyRate: this.activeAssociate.employeeHourlyRate,
              employeeJobClass: this.activeAssociate.employeeJobClass,
              employeeBranch1: this.activeAssociate.employeeBranch1,
              employeeBranch2: this.activeAssociate.employeeBranch2,
              employeeBranch3: this.activeAssociate.employeeBranch3,
              shiftWeek: this.getWeekNo(this.activeDate),
              shiftDate: this.activeDate,
              timeIn: this.timeIn,
              timeOut: newTimeOut,
              notes: this.notes,
              branchId: this.currentBranch,
              paycode: "Regular",
              sendMail: "",
              totalLoggedHrs: 0,
              loggedTimings: "",
              breakTimings: '',
            };

            this.insertTimings(schDetailRegular);

            const schDetailOverTime: ScheduleList = {
              counterId: 0,
              capacityChk: capacity,
              brManagerId: this.associate,
              employeeId: this.activeAssociate.employeeId,
              employeeFirstName: this.activeAssociate.employeeFirstName,
              employeeLastName: this.activeAssociate.employeeLastName,
              employeeMagic: this.activeAssociate.employeeMagic,
              employeeHourlyRate: this.activeAssociate.employeeHourlyRate,
              employeeJobClass: this.activeAssociate.employeeJobClass,
              employeeBranch1: this.activeAssociate.employeeBranch1,
              employeeBranch2: this.activeAssociate.employeeBranch2,
              employeeBranch3: this.activeAssociate.employeeBranch3,
              shiftWeek: this.getWeekNo(this.activeDate),
              shiftDate: this.activeDate,
              timeIn: newTimeOut,
              timeOut: this.timeOut,
              notes: this.notes,
              branchId: this.currentBranch,
              paycode: "Overtime",
              sendMail: "",
              totalLoggedHrs: 0,
              loggedTimings: "",
              breakTimings: '',
            };

            this.insertTimings(schDetailOverTime);
          } else if (
            NetWeekHrs <= this.weeklyHrs &&
            NetDailyHrs >= this.dailyHrs
          ) {
            const schDetail: ScheduleList = {
              counterId: 0,
              capacityChk: capacity,
              brManagerId: this.associate,
              employeeId: this.activeAssociate.employeeId,
              employeeFirstName: this.activeAssociate.employeeFirstName,
              employeeLastName: this.activeAssociate.employeeLastName,
              employeeMagic: this.activeAssociate.employeeMagic,
              employeeHourlyRate: this.activeAssociate.employeeHourlyRate,
              employeeJobClass: this.activeAssociate.employeeJobClass,
              employeeBranch1: this.activeAssociate.employeeBranch1,
              employeeBranch2: this.activeAssociate.employeeBranch2,
              employeeBranch3: this.activeAssociate.employeeBranch3,
              shiftWeek: this.getWeekNo(this.activeDate),
              shiftDate: this.activeDate,
              timeIn: this.timeIn,
              timeOut: this.timeOut,
              notes: this.notes,
              branchId: this.currentBranch,
              paycode: "Overtime",
              sendMail: "",
              totalLoggedHrs: 0,
              loggedTimings: "",
              breakTimings: '',
            };

            this.insertTimings(schDetail);
          } else if (
            totalRegWeekHrs < this.weeklyHrs &&
            NetWeekHrs > this.weeklyHrs
          ) {
            const newTimeOut = this.newTimeOut(
              WeekHrs,
              this.weeklyHrs,
              this.timeIn
            );

            const schDetailRegular: ScheduleList = {
              counterId: 0,
              capacityChk: capacity,
              brManagerId: this.associate,
              employeeId: this.activeAssociate.employeeId,
              employeeFirstName: this.activeAssociate.employeeFirstName,
              employeeLastName: this.activeAssociate.employeeLastName,
              employeeMagic: this.activeAssociate.employeeMagic,
              employeeHourlyRate: this.activeAssociate.employeeHourlyRate,
              employeeJobClass: this.activeAssociate.employeeJobClass,
              employeeBranch1: this.activeAssociate.employeeBranch1,
              employeeBranch2: this.activeAssociate.employeeBranch2,
              employeeBranch3: this.activeAssociate.employeeBranch3,
              shiftWeek: this.getWeekNo(this.activeDate),
              shiftDate: this.activeDate,
              timeIn: this.timeIn,
              timeOut: newTimeOut,
              notes: this.notes,
              branchId: this.currentBranch,
              paycode: "Regular",
              sendMail: "",
              totalLoggedHrs: 0,
              loggedTimings: "",
              breakTimings: '',
            };

            this.insertTimings(schDetailRegular);

            const schDetailOverTime: ScheduleList = {
              counterId: 0,
              capacityChk: capacity,
              brManagerId: this.associate,
              employeeId: this.activeAssociate.employeeId,
              employeeFirstName: this.activeAssociate.employeeFirstName,
              employeeLastName: this.activeAssociate.employeeLastName,
              employeeMagic: this.activeAssociate.employeeMagic,
              employeeHourlyRate: this.activeAssociate.employeeHourlyRate,
              employeeJobClass: this.activeAssociate.employeeJobClass,
              employeeBranch1: this.activeAssociate.employeeBranch1,
              employeeBranch2: this.activeAssociate.employeeBranch2,
              employeeBranch3: this.activeAssociate.employeeBranch3,
              shiftWeek: this.getWeekNo(this.activeDate),
              shiftDate: this.activeDate,
              timeIn: newTimeOut,
              timeOut: this.timeOut,
              notes: this.notes,
              branchId: this.currentBranch,
              paycode: "Overtime",
              sendMail: "",
              totalLoggedHrs: 0,
              loggedTimings: "",
              breakTimings: '',
            };

            this.insertTimings(schDetailOverTime);
          } else if (totalRegWeekHrs >= this.weeklyHrs) {
            const schDetail: ScheduleList = {
              counterId: 0,
              capacityChk: capacity,
              brManagerId: this.associate,
              employeeId: this.activeAssociate.employeeId,
              employeeFirstName: this.activeAssociate.employeeFirstName,
              employeeLastName: this.activeAssociate.employeeLastName,
              employeeMagic: this.activeAssociate.employeeMagic,
              employeeHourlyRate: this.activeAssociate.employeeHourlyRate,
              employeeJobClass: this.activeAssociate.employeeJobClass,
              employeeBranch1: this.activeAssociate.employeeBranch1,
              employeeBranch2: this.activeAssociate.employeeBranch2,
              employeeBranch3: this.activeAssociate.employeeBranch3,
              shiftWeek: this.getWeekNo(this.activeDate),
              shiftDate: this.activeDate,
              timeIn: this.timeIn,
              timeOut: this.timeOut,
              notes: this.notes,
              branchId: this.currentBranch,
              paycode: "Overtime",
              sendMail: "",
              totalLoggedHrs: 0,
              loggedTimings: "",
              breakTimings: '',
            };

            this.insertTimings(schDetail);
          }
        }

        this.shiftDialog = false;
        this.clearDetails();
      } else {
        this.toast.showInfo("Sorry associate is already occupied");
      }
    }
  }

  insertTimings(schDetail) {
    if (
      this.timeIn == "" ||
      this.timeOut == "" ||
      this.selectedPaycode.name == ""
    ) {
      this.toast.showWarning(
        "Please enter IN/OUT time and Paycode for associate to continue"
      );
    } else if (this.timeIn > this.timeOut) {
      this.toast.showWarning("Invalid timings");
    } else {
      if (this.activeDayName == "sunday") {
        schDetail.breakTimings = this.checkBreakTimings(this.mapList[this.activeAssociate.index].sunday,schDetail);
        this.mapList[this.activeAssociate.index].sunday.push(schDetail);
      } else if (this.activeDayName == "monday") {
        schDetail.breakTimings = this.checkBreakTimings(this.mapList[this.activeAssociate.index].monday,schDetail);
        this.mapList[this.activeAssociate.index].monday.push(schDetail);
      } else if (this.activeDayName == "tuesday") {
        schDetail.breakTimings = this.checkBreakTimings(this.mapList[this.activeAssociate.index].tuesday,schDetail);
        this.mapList[this.activeAssociate.index].tuesday.push(schDetail);
      } else if (this.activeDayName == "wednesday") {
        schDetail.breakTimings = this.checkBreakTimings(this.mapList[this.activeAssociate.index].wednesday,schDetail);
        this.mapList[this.activeAssociate.index].wednesday.push(schDetail);
      } else if (this.activeDayName == "thursday") {
        schDetail.breakTimings = this.checkBreakTimings(this.mapList[this.activeAssociate.index].thursday,schDetail);
        this.mapList[this.activeAssociate.index].thursday.push(schDetail);
      } else if (this.activeDayName == "friday") {
        schDetail.breakTimings = this.checkBreakTimings(this.mapList[this.activeAssociate.index].friday,schDetail);
        this.mapList[this.activeAssociate.index].friday.push(schDetail);
      } else if (this.activeDayName == "saturday") {
        schDetail.breakTimings = this.checkBreakTimings(this.mapList[this.activeAssociate.index].saturday,schDetail);
        this.mapList[this.activeAssociate.index].saturday.push(schDetail);
      }

      if (schDetail.paycode == "Overtime") {
        this.toast.showInfo("Overtime is added");
      }

      this.toast.showSuccess("Shift timings added successfully");
    }
  }

  deleteShift() {
    if (this.activeDayName == "sunday") {
      this.mapList[this.activeAssociate.index].sunday.splice(
        this.activeAssociate.shiftIndex,
        1
      );
    } else if (this.activeDayName == "monday") {
      this.mapList[this.activeAssociate.index].monday.splice(
        this.activeAssociate.shiftIndex,
        1
      );
    } else if (this.activeDayName == "tuesday") {
      this.mapList[this.activeAssociate.index].tuesday.splice(
        this.activeAssociate.shiftIndex,
        1
      );
    } else if (this.activeDayName == "wednesday") {
      this.mapList[this.activeAssociate.index].wednesday.splice(
        this.activeAssociate.shiftIndex,
        1
      );
    } else if (this.activeDayName == "thursday") {
      this.mapList[this.activeAssociate.index].thursday.splice(
        this.activeAssociate.shiftIndex,
        1
      );
    } else if (this.activeDayName == "friday") {
      this.mapList[this.activeAssociate.index].friday.splice(
        this.activeAssociate.shiftIndex,
        1
      );
    } else if (this.activeDayName == "saturday") {
      this.mapList[this.activeAssociate.index].saturday.splice(
        this.activeAssociate.shiftIndex,
        1
      );
    }
    this.removeOT();
    this.toast.showSuccess("Shift timings deleted successfully");
    this.deleteShiftDialog = false;
    this.clearDetails();
  }

  //USED TO DELETE OT FOR THE ASSOCIATE FOR CURRENT WEEK
  removeOT() {
    //DELETE ANY OT
    this.mapList[this.activeAssociate.index].sunday.forEach((e, i) => {
      if (e.paycode == "Overtime") {
        this.mapList[this.activeAssociate.index].sunday.splice(i, 1);
      }
    });

    this.mapList[this.activeAssociate.index].monday.forEach((e, i) => {
      if (e.paycode == "Overtime") {
        this.mapList[this.activeAssociate.index].monday.splice(i, 1);
      }
    });

    this.mapList[this.activeAssociate.index].tuesday.forEach((e, i) => {
      if (e.paycode == "Overtime") {
        this.mapList[this.activeAssociate.index].tuesday.splice(i, 1);
      }
    });

    this.mapList[this.activeAssociate.index].wednesday.forEach((e, i) => {
      if (e.paycode == "Overtime") {
        this.mapList[this.activeAssociate.index].wednesday.splice(i, 1);
      }
    });

    this.mapList[this.activeAssociate.index].thursday.forEach((e, i) => {
      if (e.paycode == "Overtime") {
        this.mapList[this.activeAssociate.index].thursday.splice(i, 1);
      }
    });

    this.mapList[this.activeAssociate.index].friday.forEach((e, i) => {
      if (e.paycode == "Overtime") {
        this.mapList[this.activeAssociate.index].friday.splice(i, 1);
      }
    });

    this.mapList[this.activeAssociate.index].saturday.forEach((e, i) => {
      if (e.paycode == "Overtime") {
        this.mapList[this.activeAssociate.index].saturday.splice(i, 1);
      }
    });
  }

  clearDetails() {
    this.timeIn = "10:00";
    this.timeOut = "18:00";
    this.notes = "";
    this.selectedPaycode = { name: "Regular" };
    this.capacity = true;
    this.activeAssociate.employeeId = "";
    this.activeAssociate.employeeFirstName = "";
    this.activeAssociate.employeeLastName = "";
    this.activeAssociate.employeeMagic = 0;
    this.activeAssociate.employeeHourlyRate = 0;
    this.activeAssociate.employeeJobClass = "";
    this.activeAssociate.index = 0;
    this.activeAssociate.shiftIndex = 0;
    this.activeDate = "";
    this.activeDayName = "";
  }

  postSchedule() {
    const schedule: ScheduleList[] = [];

    this.mapList.forEach((e) => {
      if (e.code == this.currentBranch) {
        e.sunday.forEach((i) => {
          schedule.push(i);
        });

        e.monday.forEach((i) => {
          schedule.push(i);
        });

        e.tuesday.forEach((i) => {
          schedule.push(i);
        });

        e.wednesday.forEach((i) => {
          schedule.push(i);
        });

        e.thursday.forEach((i) => {
          schedule.push(i);
        });

        e.friday.forEach((i) => {
          schedule.push(i);
        });

        e.saturday.forEach((i) => {
          schedule.push(i);
        });
      }
    });

    this.schTxn
      .publishSchedule(schedule, this.weekDates.saturday, this.projSales)
      .then((res) => {
        this.toast.handleResponse(res);
      });
  }

  toggleSchedule() {
    if (this.toggleType == "full") {
      this.toggleType = "min";
    } else {
      this.toggleType = "full";
    }
  }

  closeOptionDialog(param) {
    if (param != "") {
      this.currentDate = param;
    }

    this.loadDetails();
    this.optionsDialog = false;
  }

  nextWeek() {
    this.currentDate = this.nextDate;
    this.loadDetails();
  }

  previousWeek() {
    this.currentDate = this.previousDate;
    this.loadDetails();
  }

  get totalDayHours() {
    const hours = [0, 0, 0, 0, 0, 0, 0];
    return hours;
  }

  getAssociateStoreHrs(empID,storeID) {
    const l = this.getMapedSchedule;

    let totalHrs = 0;
    let totalMints = 0;
 
    l.forEach((e) => {
      if (e.employeeId == empID && e.code == storeID) {
        e.sunday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.monday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.tuesday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.wednesday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.thursday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.friday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.saturday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });
      }
    });

    const quotient = Math.floor(totalMints / 60);
    const remainder = totalMints % 60;
    return totalHrs + quotient + " Hrs & " + remainder + " min";
  }

  getAssociateHrs(empID) {
    const l = this.getMapedSchedule;

    let totalHrs = 0;
    let totalMints = 0;

    l.forEach((e) => {
      if (e.employeeId == empID) {
        e.sunday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.monday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.tuesday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.wednesday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.thursday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.friday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });

        e.saturday.forEach((i) => {
          const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, i.breakTimings);
          totalHrs = totalHrs + d.hours();
          totalMints = totalMints + d.minutes();
        });
      }
    });

    const quotient = Math.floor(totalMints / 60);
    const remainder = totalMints % 60;

    return totalHrs + quotient + " Hrs & " + remainder + " min";
  }

  totalWeekRegularHrs(empID) {
    const l = this.getMapedSchedule;

    let totalHrs = 0;
    let totalMints = 0;

    l.forEach((e) => {
      if (e.employeeId == empID) {
        e.sunday.forEach((i) => {
          if (i.paycode == "Regular") {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            totalHrs = totalHrs + d.hours();
            totalMints = totalMints + d.minutes();
          }
        });

        e.monday.forEach((i) => {
          if (i.paycode == "Regular") {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            totalHrs = totalHrs + d.hours();
            totalMints = totalMints + d.minutes();
          }
        });

        e.tuesday.forEach((i) => {
          if (i.paycode == "Regular") {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            totalHrs = totalHrs + d.hours();
            totalMints = totalMints + d.minutes();
          }
        });

        e.wednesday.forEach((i) => {
          if (i.paycode == "Regular") {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            totalHrs = totalHrs + d.hours();
            totalMints = totalMints + d.minutes();
          }
        });

        e.thursday.forEach((i) => {
          if (i.paycode == "Regular") {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            totalHrs = totalHrs + d.hours();
            totalMints = totalMints + d.minutes();
          }
        });

        e.friday.forEach((i) => {
          if (i.paycode == "Regular") {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            totalHrs = totalHrs + d.hours();
            totalMints = totalMints + d.minutes();
          }
        });

        e.saturday.forEach((i) => {
          if (i.paycode == "Regular") {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            totalHrs = totalHrs + d.hours();
            totalMints = totalMints + d.minutes();
          }
        });
      }
    });

    const quotient = Math.floor(totalMints / 60);
    const remainder = totalMints % 60;

    return [totalHrs + quotient, remainder];
  }

  totalDailyRegularHrs(empID, dayName, paycode) {
    const l = this.getMapedSchedule;

    let totalHrs = 0;
    let totalMints = 0;

    l.forEach((e) => {
      if (e.employeeId == empID) {
        if (dayName == "Sunday") {
          e.sunday.forEach((i) => {
            if (i.paycode == paycode) {
              const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, "");
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        }

        if (dayName == "Monday") {
          e.monday.forEach((i) => {
            if (i.paycode == paycode) {
              const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, "");
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        }

        if (dayName == "Tuesday") {
          e.tuesday.forEach((i) => {
            if (i.paycode == paycode) {
              const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, "");
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        }

        if (dayName == "Wednesday") {
          e.wednesday.forEach((i) => {
            if (i.paycode == paycode) {
              const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, "");
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        }

        if (dayName == "Thursday") {
          e.thursday.forEach((i) => {
            if (i.paycode == paycode) {
              const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, "");
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        }

        if (dayName == "Friday") {
          e.friday.forEach((i) => {
            if (i.paycode == paycode) {
              const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, "");
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        }

        if (dayName == "Saturday") {
          e.saturday.forEach((i) => {
            if (i.paycode == paycode) {
              const d = this.getTimeDiffmoment(i.timeIn, i.timeOut, "");
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        }
      }
    });

    const quotient = Math.floor(totalMints / 60);
    const remainder = totalMints % 60;

    return [totalHrs + quotient, remainder];
  }

  getStoreDayeHrs(dayName) {
    const l = this.getMapedSchedule;

    let totalHrs = 0;
    let totalMints = 0;

    l.forEach((e) => {
      if (e.code == this.currentBranch) {
        if (dayName == "sunday") {
          e.sunday.forEach((i) => {
            if (
              i.paycode == "Regular" ||
              i.paycode == "Overtime" ||
              i.paycode == "Stat Holiday"
            ) {
              const d = this.getTimeDiffmoment(
                i.timeIn,
                i.timeOut,
                i.breakTimings
              );
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        } else if (dayName == "monday") {
          e.monday.forEach((i) => {
            if (
              i.paycode == "Regular" ||
              i.paycode == "Overtime" ||
              i.paycode == "Stat Holiday"
            ) {
              const d = this.getTimeDiffmoment(
                i.timeIn,
                i.timeOut,
                i.breakTimings
              );
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        } else if (dayName == "tuesday") {
          e.tuesday.forEach((i) => {
            if (
              i.paycode == "Regular" ||
              i.paycode == "Overtime" ||
              i.paycode == "Stat Holiday"
            ) {
              const d = this.getTimeDiffmoment(
                i.timeIn,
                i.timeOut,
                i.breakTimings
              );
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        } else if (dayName == "wednesday") {
          e.wednesday.forEach((i) => {
            if (
              i.paycode == "Regular" ||
              i.paycode == "Overtime" ||
              i.paycode == "Stat Holiday"
            ) {
              const d = this.getTimeDiffmoment(
                i.timeIn,
                i.timeOut,
                i.breakTimings
              );
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        } else if (dayName == "thursday") {
          e.thursday.forEach((i) => {
            if (
              i.paycode == "Regular" ||
              i.paycode == "Overtime" ||
              i.paycode == "Stat Holiday"
            ) {
              const d = this.getTimeDiffmoment(
                i.timeIn,
                i.timeOut,
                i.breakTimings
              );
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        } else if (dayName == "friday") {
          e.friday.forEach((i) => {
            if (
              i.paycode == "Regular" ||
              i.paycode == "Overtime" ||
              i.paycode == "Stat Holiday"
            ) {
              const d = this.getTimeDiffmoment(
                i.timeIn,
                i.timeOut,
                i.breakTimings
              );
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        } else if (dayName == "saturday") {
          e.saturday.forEach((i) => {
            if (
              i.paycode == "Regular" ||
              i.paycode == "Overtime" ||
              i.paycode == "Stat Holiday"
            ) {
              const d = this.getTimeDiffmoment(
                i.timeIn,
                i.timeOut,
                i.breakTimings
              );
              totalHrs = totalHrs + d.hours();
              totalMints = totalMints + d.minutes();
            }
          });
        } else {
          //
        }
      }
    });

    const quotient = Math.floor(totalMints / 60);
    const remainder = totalMints % 60;
    return totalHrs + quotient + " Hrs & " + remainder + " min";
  }

  get totalProjectedSales() {
    const amt =
      Number(this.projSales.sunday) +
      Number(this.projSales.monday) +
      Number(this.projSales.tuesday) +
      Number(this.projSales.wednesday) +
      Number(this.projSales.thursday) +
      Number(this.projSales.friday) +
      Number(this.projSales.saturday);

    return this.formatAmount(amt);
  }

  get totalActualProd() {
    const total = [
      Number(this.actualAmt[0] + this.expressAmt[0]),
      Number(this.actualAmt[1] + this.expressAmt[1]),
      Number(this.actualAmt[2] + this.expressAmt[2]),
      Number(this.actualAmt[3] + this.expressAmt[3]),
      Number(this.actualAmt[4] + this.expressAmt[4]),
      Number(this.actualAmt[5] + this.expressAmt[5]),
      Number(this.actualAmt[6] + this.expressAmt[6]),
    ];

    return total;
  }

  get labourProduction() {
    const associateExpense = {
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
    };

    const associateMagic = {
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
    };

    const actualLabour = {
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
    };

    const l = this.getMapedSchedule;

    l.forEach((e) => {
      if (e.code == this.currentBranch) {
        e.sunday.forEach((i) => {
          if (
            i.paycode == "Regular" ||
            i.paycode == "Overtime" ||
            i.paycode == "Training" ||
            i.paycode == "Meet Con" ||
            i.paycode == "Stat Holiday"
          ) {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            const totalHrs = d.hours();
            const totalMints = d.minutes();

            let totalAmt = 0;
            let totalActualAmt = 0;

            if (
              i.paycode == "Regular" ||
              i.paycode == "Training" ||
              i.paycode == "Meet Con"
            ) {
              totalAmt = totalAmt + totalHrs * Number(i.employeeHourlyRate);
              totalAmt =
                totalAmt + totalMints * Number(i.employeeHourlyRate / 60);

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate);
            } else if (i.paycode == "Overtime") {
              const OT = (i.employeeHourlyRate / 100) * this.overtimeRate;
              const OTHrs = totalHrs * Number(OT);
              const OTMints = totalMints * Number(OT / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + OTHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                OTMints;

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate) +OT * a;
              
            } else if (i.paycode == "Stat Holiday") {
              const SH = (i.employeeHourlyRate / 100) * this.statHolidayRate;
              const SHHrs = totalHrs * Number(SH);
              const SHMints = totalMints * Number(SH / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + SHHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                SHMints;

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate) + SH * a;
            }

            associateExpense.sunday = associateExpense.sunday + totalAmt;
            actualLabour.sunday = actualLabour.sunday + totalActualAmt;
            associateMagic.sunday =
              associateMagic.sunday + totalHrs * Number(i.employeeMagic);
            associateMagic.sunday =
              associateMagic.sunday +
              totalMints * (Number(i.employeeMagic) / 60);
          }
        });

        e.monday.forEach((i) => {
          if (
            i.paycode == "Regular" ||
            i.paycode == "Overtime" ||
            i.paycode == "Training" ||
            i.paycode == "Meet Con" ||
            i.paycode == "Stat Holiday"
          ) {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            const totalHrs = d.hours();
            const totalMints = d.minutes();

            let totalAmt = 0;
            let totalActualAmt = 0;

            if (
              i.paycode == "Regular" ||
              i.paycode == "Training" ||
              i.paycode == "Meet Con"
            ) {
              totalAmt = totalAmt + totalHrs * Number(i.employeeHourlyRate);
              totalAmt =
                totalAmt + totalMints * Number(i.employeeHourlyRate / 60);
              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate);
            } else if (i.paycode == "Overtime") {
              const OT = (i.employeeHourlyRate / 100) * this.overtimeRate;
              const OTHrs = totalHrs * Number(OT);
              const OTMints = totalMints * Number(OT / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + OTHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                OTMints;

             const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate) +OT * a;
            } else if (i.paycode == "Stat Holiday") {
              const SH = (i.employeeHourlyRate / 100) * this.statHolidayRate;
              const SHHrs = totalHrs * Number(SH);
              const SHMints = totalMints * Number(SH / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + SHHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                SHMints;

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate) + SH * a;
            }

            associateExpense.monday = associateExpense.monday + totalAmt;
            actualLabour.monday = actualLabour.monday + totalActualAmt;
            associateMagic.monday =
              associateMagic.monday + totalHrs * Number(i.employeeMagic);
            associateMagic.monday =
              associateMagic.monday +
              totalMints * (Number(i.employeeMagic) / 60);
          }
        });

        e.tuesday.forEach((i) => {
          if (
            i.paycode == "Regular" ||
            i.paycode == "Overtime" ||
            i.paycode == "Training" ||
            i.paycode == "Meet Con" ||
            i.paycode == "Stat Holiday"
          ) {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            const totalHrs = d.hours();
            const totalMints = d.minutes();

            let totalAmt = 0;
            let totalActualAmt = 0;

            if (
              i.paycode == "Regular" ||
              i.paycode == "Training" ||
              i.paycode == "Meet Con"
            ) {
              totalAmt = totalAmt + totalHrs * Number(i.employeeHourlyRate);
              totalAmt =
                totalAmt + totalMints * Number(i.employeeHourlyRate / 60);

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate);
            } else if (i.paycode == "Overtime") {
              const OT = (i.employeeHourlyRate / 100) * this.overtimeRate;
              const OTHrs = totalHrs * Number(OT);
              const OTMints = totalMints * Number(OT / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + OTHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                OTMints;

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate) +OT * a;
              
            } else if (i.paycode == "Stat Holiday") {
              const SH = (i.employeeHourlyRate / 100) * this.statHolidayRate;
              const SHHrs = totalHrs * Number(SH);
              const SHMints = totalMints * Number(SH / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + SHHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                SHMints;

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate) + SH * a;
            }

            associateExpense.tuesday = associateExpense.tuesday + totalAmt;
            actualLabour.tuesday = actualLabour.tuesday + totalActualAmt;
            associateMagic.tuesday =
              associateMagic.tuesday + totalHrs * Number(i.employeeMagic);
            associateMagic.tuesday =
              associateMagic.tuesday +
              totalMints * (Number(i.employeeMagic) / 60);
          }
        });

        e.wednesday.forEach((i) => {
          if (
            i.paycode == "Regular" ||
            i.paycode == "Overtime" ||
            i.paycode == "Training" ||
            i.paycode == "Meet Con" ||
            i.paycode == "Stat Holiday"
          ) {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            const totalHrs = d.hours();
            const totalMints = d.minutes();

            let totalAmt = 0;
            let totalActualAmt = 0;

            if (
              i.paycode == "Regular" ||
              i.paycode == "Training" ||
              i.paycode == "Meet Con"
            ) {
              totalAmt = totalAmt + totalHrs * Number(i.employeeHourlyRate);
              totalAmt =
                totalAmt + totalMints * Number(i.employeeHourlyRate / 60);

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate);
            } else if (i.paycode == "Overtime") {
              const OT = (i.employeeHourlyRate / 100) * this.overtimeRate;
              const OTHrs = totalHrs * Number(OT);
              const OTMints = totalMints * Number(OT / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + OTHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                OTMints;

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate) +OT * a;
            } else if (i.paycode == "Stat Holiday") {
              const SH = (i.employeeHourlyRate / 100) * this.statHolidayRate;
              const SHHrs = totalHrs * Number(SH);
              const SHMints = totalMints * Number(SH / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + SHHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                SHMints;

               const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
               totalActualAmt = a * Number(i.employeeHourlyRate) + SH * a;
            }

            associateExpense.wednesday = associateExpense.wednesday + totalAmt;
            actualLabour.wednesday = actualLabour.wednesday + totalActualAmt;
            associateMagic.wednesday =
              associateMagic.wednesday + totalHrs * Number(i.employeeMagic);
            associateMagic.wednesday =
              associateMagic.wednesday +
              totalMints * (Number(i.employeeMagic) / 60);
          }
        });

        e.thursday.forEach((i) => {
          if (
            i.paycode == "Regular" ||
            i.paycode == "Overtime" ||
            i.paycode == "Training" ||
            i.paycode == "Meet Con" ||
            i.paycode == "Stat Holiday"
          ) {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            const totalHrs = d.hours();
            const totalMints = d.minutes();

            let totalAmt = 0;
            let totalActualAmt = 0;

            if (
              i.paycode == "Regular" ||
              i.paycode == "Training" ||
              i.paycode == "Meet Con"
            ) {
              totalAmt = totalAmt + totalHrs * Number(i.employeeHourlyRate);
              totalAmt =
                totalAmt + totalMints * Number(i.employeeHourlyRate / 60);

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate);
            } else if (i.paycode == "Overtime") {
              const OT = (i.employeeHourlyRate / 100) * this.overtimeRate;
              const OTHrs = totalHrs * Number(OT);
              const OTMints = totalMints * Number(OT / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + OTHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                OTMints;

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate) +OT * a;
            } else if (i.paycode == "Stat Holiday") {
              const SH = (i.employeeHourlyRate / 100) * this.statHolidayRate;
              const SHHrs = totalHrs * Number(SH);
              const SHMints = totalMints * Number(SH / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + SHHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                SHMints;

               const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
               totalActualAmt = a * Number(i.employeeHourlyRate) + SH * a;
            }

            associateExpense.thursday = associateExpense.thursday + totalAmt;
            actualLabour.thursday = actualLabour.thursday + totalActualAmt;
            associateMagic.thursday =
              associateMagic.thursday + totalHrs * Number(i.employeeMagic);
            associateMagic.thursday =
              associateMagic.thursday +
              totalMints * (Number(i.employeeMagic) / 60);
          }
        });

        e.friday.forEach((i) => {
          if (
            i.paycode == "Regular" ||
            i.paycode == "Overtime" ||
            i.paycode == "Training" ||
            i.paycode == "Meet Con" ||
            i.paycode == "Stat Holiday"
          ) {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            const totalHrs = d.hours();
            const totalMints = d.minutes();

            let totalAmt = 0;
            let totalActualAmt = 0;

            if (
              i.paycode == "Regular" ||
              i.paycode == "Training" ||
              i.paycode == "Meet Con"
            ) {
              totalAmt = totalAmt + totalHrs * Number(i.employeeHourlyRate);
              totalAmt =
                totalAmt + totalMints * Number(i.employeeHourlyRate / 60);

             const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate);
            } else if (i.paycode == "Overtime") {
              const OT = (i.employeeHourlyRate / 100) * this.overtimeRate;
              const OTHrs = totalHrs * Number(OT);
              const OTMints = totalMints * Number(OT / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + OTHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                OTMints;

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate) +OT * a;
            } else if (i.paycode == "Stat Holiday") {
              const SH = (i.employeeHourlyRate / 100) * this.statHolidayRate;
              const SHHrs = totalHrs * Number(SH);
              const SHMints = totalMints * Number(SH / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + SHHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                SHMints;

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate) + SH * a;
            }

            associateExpense.friday = associateExpense.friday + totalAmt;
            actualLabour.friday = actualLabour.friday + totalActualAmt;
            associateMagic.friday =
              associateMagic.friday + totalHrs * Number(i.employeeMagic);
            associateMagic.friday =
              associateMagic.friday +
              totalMints * (Number(i.employeeMagic) / 60);
          }
        });

        e.saturday.forEach((i) => {
          if (
            i.paycode == "Regular" ||
            i.paycode == "Overtime" ||
            i.paycode == "Training" ||
            i.paycode == "Meet Con" ||
            i.paycode == "Stat Holiday"
          ) {
            const d = this.getTimeDiffmoment(
              i.timeIn,
              i.timeOut,
              i.breakTimings
            );
            const totalHrs = d.hours();
            const totalMints = d.minutes();

            let totalAmt = 0;
            let totalActualAmt = 0;

            if (
              i.paycode == "Regular" ||
              i.paycode == "Training" ||
              i.paycode == "Meet Con"
            ) {
              totalAmt = totalAmt + totalHrs * Number(i.employeeHourlyRate);
              totalAmt =
                totalAmt + totalMints * Number(i.employeeHourlyRate / 60);

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate);
            } else if (i.paycode == "Overtime") {
              const OT = (i.employeeHourlyRate / 100) * this.overtimeRate;
              const OTHrs = totalHrs * Number(OT);
              const OTMints = totalMints * Number(OT / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + OTHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                OTMints;

              const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
              totalActualAmt = a * Number(i.employeeHourlyRate) +OT * a;
            } else if (i.paycode == "Stat Holiday") {
              const SH = (i.employeeHourlyRate / 100) * this.statHolidayRate;
              const SHHrs = totalHrs * Number(SH);
              const SHMints = totalMints * Number(SH / 60);

              totalAmt =
                totalAmt + totalHrs * Number(i.employeeHourlyRate) + SHHrs;
              totalAmt =
                totalAmt +
                totalMints * Number(i.employeeHourlyRate / 60) +
                SHMints;

               const a = this.sumPaycodeApprovedHrs(i.paycode,i.employeeId,i.shiftDate);
               totalActualAmt = a * Number(i.employeeHourlyRate) + SH * a;
            }

            associateExpense.saturday = associateExpense.saturday + totalAmt;
            actualLabour.saturday = actualLabour.saturday + totalActualAmt;
            associateMagic.saturday =
              associateMagic.saturday + totalHrs * Number(i.employeeMagic);
            associateMagic.saturday =
              associateMagic.saturday +
              totalMints * (Number(i.employeeMagic) / 60);
          }
        });
      }
    });

    const labourProd = {
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      prodSunday: associateMagic.sunday,
      prodMonday: associateMagic.monday,
      prodTuesday: associateMagic.tuesday,
      prodWednesday: associateMagic.wednesday,
      prodThursday: associateMagic.thursday,
      prodFriday: associateMagic.friday,
      prodSaturday: associateMagic.saturday,
      actualLabourSunday: 0,
      actualLabourMonday: 0,
      actualLabourTuesday: 0,
      actualLabourWednesday: 0,
      actualLabourThursday: 0,
      actualLabourFriday: 0,
      actualLabourSaturday: 0,
    };


    if (this.projSales.sunday != 0) {
      labourProd.sunday =
        (associateExpense.sunday / this.projSales.sunday) * 100;
    }

    if (this.projSales.monday != 0) {
      labourProd.monday =
        (associateExpense.monday / this.projSales.monday) * 100;
    }

    if (this.projSales.tuesday != 0) {
      labourProd.tuesday =
        (associateExpense.tuesday / this.projSales.tuesday) * 100;
    }

    if (this.projSales.wednesday != 0) {
      labourProd.wednesday =
        (associateExpense.wednesday / this.projSales.wednesday) * 100;
    }

    if (this.projSales.thursday != 0) {
      labourProd.thursday =
        (associateExpense.thursday / this.projSales.thursday) * 100;
    }

    if (this.projSales.friday != 0) {
      labourProd.friday =
        (associateExpense.friday / this.projSales.friday) * 100;
    }

    if (this.projSales.saturday != 0) {
      labourProd.saturday =
        (associateExpense.saturday / this.projSales.saturday) * 100;
    }

    if (this.weekReceiptTotal[0] != 0) {
      labourProd.actualLabourSunday =
        (actualLabour.sunday / this.weekReceiptTotal[0]) * 100;
    }

    if (this.weekReceiptTotal[1] != 0) {
      labourProd.actualLabourMonday =
        (actualLabour.monday / this.weekReceiptTotal[1]) * 100;
    }

    if (this.weekReceiptTotal[2] != 0) {
      labourProd.actualLabourTuesday =
        (actualLabour.tuesday / this.weekReceiptTotal[2]) * 100;
    }

    if (this.weekReceiptTotal[3] != 0) {
      labourProd.actualLabourWednesday =
        (actualLabour.wednesday / this.weekReceiptTotal[3]) * 100;
    }

    if (this.weekReceiptTotal[4] != 0) {
      labourProd.actualLabourThursday =
        (actualLabour.thursday / this.weekReceiptTotal[4]) * 100;
    }

    if (this.weekReceiptTotal[5] != 0) {
      labourProd.actualLabourFriday =
        (actualLabour.friday / this.weekReceiptTotal[5]) * 100;
    }

    if (this.weekReceiptTotal[6] != 0) {
      labourProd.actualLabourSaturday =
        (actualLabour.saturday / this.weekReceiptTotal[6]) * 100;
    }

    return labourProd;
  }

  get totalProjLabour() {
    let totalLabour = 0;

    const total =
      this.labourProduction.sunday +
      this.labourProduction.monday +
      this.labourProduction.tuesday +
      this.labourProduction.wednesday +
      this.labourProduction.thursday +
      this.labourProduction.saturday +
      this.labourProduction.friday;

    if (this.totalProjectedSales != 0) {
      totalLabour = (total / this.totalProjectedSales) * 100;
    }
  
    return totalLabour;
  }

  get totalActualLabour() {
    const total =
      this.labourProduction.actualLabourSunday +
      this.labourProduction.actualLabourMonday +
      this.labourProduction.actualLabourTuesday +
      this.labourProduction.actualLabourWednesday +
      this.labourProduction.actualLabourThursday +
      this.labourProduction.actualLabourFriday +
      this.labourProduction.actualLabourSaturday;

    return total;
  }

  checkTimeAlocated(timeIn, timeOut, employeeId, dayName) {
    let availabilityStatus = "available";

    this.mapList.forEach((e) => {
      if (e.employeeId == employeeId && dayName == "sunday") {
        e.sunday.forEach((i) => {
          const availability = this.checkMomentTimeBetween(
            timeIn,
            timeOut,
            i.timeIn,
            i.timeOut
          );
          if (availability == "occupied") {
            availabilityStatus = availability;
          }
        });
      } else if (e.employeeId == employeeId && dayName == "monday") {
        e.monday.forEach((i) => {
          const availability = this.checkMomentTimeBetween(
            timeIn,
            timeOut,
            i.timeIn,
            i.timeOut
          );
          if (availability == "occupied") {
            availabilityStatus = availability;
          }
        });
      } else if (e.employeeId == employeeId && dayName == "tuesday") {
        e.tuesday.forEach((i) => {
          const availability = this.checkMomentTimeBetween(
            timeIn,
            timeOut,
            i.timeIn,
            i.timeOut
          );
          if (availability == "occupied") {
            availabilityStatus = availability;
          }
        });
      } else if (e.employeeId == employeeId && dayName == "wednesday") {
        e.wednesday.forEach((i) => {
          const availability = this.checkMomentTimeBetween(
            timeIn,
            timeOut,
            i.timeIn,
            i.timeOut
          );
          if (availability == "occupied") {
            availabilityStatus = availability;
          }
        });
      } else if (e.employeeId == employeeId && dayName == "thursday") {
        e.thursday.forEach((i) => {
          const availability = this.checkMomentTimeBetween(
            timeIn,
            timeOut,
            i.timeIn,
            i.timeOut
          );
          if (availability == "occupied") {
            availabilityStatus = availability;
          }
        });
      } else if (e.employeeId == employeeId && dayName == "friday") {
        e.friday.forEach((i) => {
          const availability = this.checkMomentTimeBetween(
            timeIn,
            timeOut,
            i.timeIn,
            i.timeOut
          );
          if (availability == "occupied") {
            availabilityStatus = availability;
          }
        });
      } else if (e.employeeId == employeeId && dayName == "saturday") {
        e.saturday.forEach((i) => {
          const availability = this.checkMomentTimeBetween(
            timeIn,
            timeOut,
            i.timeIn,
            i.timeOut
          );
          if (availability == "occupied") {
            availabilityStatus = availability;
          }
        });
      }
    });

    return availabilityStatus;
  }

  closePINDialog(params) {
    this.associate = params[0];
    this.toast.showSuccess("Welcome " + params[1] + " " + params[2]);
  }

  confirmEmailList()
  {
    if(this.chosenAssociatesList.length == 0)
    {
      this.toast.showError('Please choose associates to send schedule email' );
    }
    else
    {
      this.emailScheduleDialog = true;
    }
  }

  screenShot()
  {
    this.emailScheduleDialog = false;

    let node = document.getElementById("schedule_assistance");
    let t = this;
    domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        t.schTxn.sendScheduleImage(dataUrl,t.associate,t.chosenAssociatesList).then((res) => {
              t.chosenAssociatesList = [];
           t.toast.handleResponse(res);
         });
      })
      .catch(function (error) {
         t.toast.showError('Something went wrong '+error);
      });
  }

  checkBreakTimings(l: ScheduleList [] ,sch : ScheduleList ) {

    let breakTimes = "";

    const noBreakTime = l.filter(e => e.breakTimings == '');
    const breakTime = l.filter(e => e.breakTimings != '');

    let h = 0;

    h = h +  Number(this.currentTimeDiffDecimal(sch.timeOut,sch.timeIn));
    const for12 = (this.storeBreakHours*2)+1;

    if(sch.paycode != "Regular" && sch.paycode != "Overtime" && sch.paycode != "Stat Holiday" && h >= for12)
    {
      breakTimes = "60 min";
    }
    else
    {
      breakTime.forEach(e => {
        const b = this.storeBreakHours + 0.50;
        let newTime = moment(e.timeIn, "HH:mm").add(String(b), "hours").format("HH:mm");
        h = h +  Number(this.currentTimeDiffDecimal(e.timeOut,newTime));
      });
      
      noBreakTime.forEach(e => {
          h = h +  Number(this.currentTimeDiffDecimal(e.timeOut,e.timeIn));
      });
  
      if(h > this.storeBreakHours )
      {
        breakTimes = "30 min";
      }
    }
    return breakTimes;
  }

  sumPaycodeApprovedHrs(paycode,associateID,date)
  {
    let h = 0;
    this.approvedHoursList.forEach(e => {
      if(e.branchId == this.currentBranch)
      {
          if(e.payCode == paycode && e.loggedDate == date && associateID == e.employeeId)
          {
              h = h + Number(e.approveHrs);
          }
      }
    });

    return h;
  }
}
</script>

<style scoped>
#schedule_assistance
{
  background-color: #fff;
}

.overTime {
  background-color: green;
  color: #fff !important;
  width: 100%;
  display: block;
}

.breakTimings {
  background-color: #dc3545;
  color: #fff;
  width: 100%;
  display: block;
}

.light-pill-color {
  color: green;
  font-size: 12px;
  margin-top: 3px;
}
.branch_employee_schedule {
  table-layout: fixed;
}
.hoverable label {
  display: block !important;
  margin: 0;
}

.table-schedule-head {
  background-color: #fff;
}

.table-schedule-head b,
i {
  font-size: 12px;
}

.bg-emp-shift-header td {
  font-size: 16px;
  color: #fff;
  background: #004c97 !important;
}

.last-header-row {
  border-bottom: 2px solid #777;
}

.send_email_link_sent {
  background-color: #ccc;
  padding: 5px;
  border-radius: 2px;
  color: #777 !important;
}

.send_email_link {
  background-color: #004c97;
  padding: 5px;
  border-radius: 2px;
  color: #fff !important;
}

.send_email_link:hover {
  color: #ccc !important;
}

#email_loading_container {
  line-height: 45px;
  font-size: 30px;
}

.emp_shift_per {
  width: 140px;
  padding: 2px;
  border-radius: 15px;
  text-align: center;
  background-color: orange;
  border: 1px solid #fff;
}

.shift-paycode {
  color: #c00;
}

.slot-style {
  border-bottom: 1px solid #ccc;
}

.schedule-details {
  font-size: 13px;
}

.gray-color {
  color: #68686b;
}

.currentStore {
  color: #c00;
}

.time-field {
  font-size: 50px;
  color: #dc4535;
  width: 350px;
  height: 70px;
}

.add-to-capacity {
  background-color: #dc3545;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 18px;
}

.table td {
  padding: 0;
}

.slot-style:hover {
  background-color: #004c97;
  color: #fff;
  cursor: pointer;
}

.schedule-btn {
  border-radius: 0px;
}

.notes-height {
  height: 20px;
}

.enableAlertLabel {
  background-color: #c00;
  border-radius: 10px;
  color: #fff;
  padding: 0 10px;
}

.enableNormalLabel {
  background-color: green;
  color: #fff;
  border-radius: 10px;
  padding: 0 10px;
}
.customer-profile-link:hover {
  text-decoration: underline;
  transition: color 0.5s ease;
  color: #004c97;
  cursor: pointer;
}
</style>
