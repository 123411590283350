<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-mx-2">
            <Button
              icon="pi pi-search"
              class="p-button-success px-4"
              @click="openDialog"
            />
          </div>
          <div class="">
            <Button
              icon="pi pi-print"
              class="p-button-primary px-4"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="m-2 mt-4 mb-4 p-text-center">
          <h5>Weekly Payments Report</h5>
          <p>{{resultTitle}}</p>
      </div>
      <div class="p-mt-2 p-p-2 ">
          <div class="p-grid" style="background-color:#004c97; color:#fff;">
            <div class="p-col"></div>
            <div class="p-col">Sunday</div>
            <div class="p-col">Monday</div>
            <div class="p-col">Tuesday</div>
            <div class="p-col">Wednesday</div>
            <div class="p-col">Thursday</div>
            <div class="p-col">Friday</div>
            <div class="p-col">Saturday</div>
          </div>
          <div class="p-grid" style="background-color:#d5d50b;">
            <div class="p-col">DATE</div>
            <div class="p-col">{{formatDate(weekDates.sunday)}}</div>
            <div class="p-col">{{formatDate(weekDates.monday)}}</div>
            <div class="p-col">{{formatDate(weekDates.tuesday)}}</div>
            <div class="p-col">{{formatDate(weekDates.wednesday)}}</div>
            <div class="p-col">{{formatDate(weekDates.thursday)}}</div>
            <div class="p-col">{{formatDate(weekDates.friday)}}</div>
            <div class="p-col">{{formatDate(weekDates.saturday)}}</div>
          </div>
          <div class="p-grid">
            <div class="p-col">AMEX</div>
            <div class="p-col">${{formatAmount(weeklyAmex[0])}}</div>
            <div class="p-col">${{formatAmount(weeklyAmex[1])}}</div>
            <div class="p-col">${{formatAmount(weeklyAmex[2])}}</div>
            <div class="p-col">${{formatAmount(weeklyAmex[3])}}</div>
            <div class="p-col">${{formatAmount(weeklyAmex[4])}}</div>
            <div class="p-col">${{formatAmount(weeklyAmex[5])}}</div>
            <div class="p-col">${{formatAmount(weeklyAmex[6])}}</div>
          </div>
          <div class="p-grid">
            <div class="p-col">CASH</div>
            <div class="p-col">${{formatAmount(weeklyCash[0])}}</div>
            <div class="p-col">${{formatAmount(weeklyCash[1])}}</div>
            <div class="p-col">${{formatAmount(weeklyCash[2])}}</div>
            <div class="p-col">${{formatAmount(weeklyCash[3])}}</div>
            <div class="p-col">${{formatAmount(weeklyCash[4])}}</div>
            <div class="p-col">${{formatAmount(weeklyCash[5])}}</div>
            <div class="p-col">${{formatAmount(weeklyCash[6])}}</div>
          </div>
          <div class="p-grid">
            <div class="p-col">DEBIT</div>
            <div class="p-col">${{formatAmount(weeklyDebit[0])}}</div>
            <div class="p-col">${{formatAmount(weeklyDebit[1])}}</div>
            <div class="p-col">${{formatAmount(weeklyDebit[2])}}</div>
            <div class="p-col">${{formatAmount(weeklyDebit[3])}}</div>
            <div class="p-col">${{formatAmount(weeklyDebit[4])}}</div>
            <div class="p-col">${{formatAmount(weeklyDebit[5])}}</div>
            <div class="p-col">${{formatAmount(weeklyDebit[6])}}</div>
          </div>
          <div class="p-grid">
            <div class="p-col">GIFT CARD</div>
            <div class="p-col">${{formatAmount(weeklyGift[0])}}</div>
            <div class="p-col">${{formatAmount(weeklyGift[1])}}</div>
            <div class="p-col">${{formatAmount(weeklyGift[2])}}</div>
            <div class="p-col">${{formatAmount(weeklyGift[3])}}</div>
            <div class="p-col">${{formatAmount(weeklyGift[4])}}</div>
            <div class="p-col">${{formatAmount(weeklyGift[5])}}</div>
            <div class="p-col">${{formatAmount(weeklyGift[6])}}</div>
          </div>
          <div class="p-grid">
            <div class="p-col">MASTER CARD</div>
            <div class="p-col">${{formatAmount(weeklyMaster[0])}}</div>
            <div class="p-col">${{formatAmount(weeklyMaster[1])}}</div>
            <div class="p-col">${{formatAmount(weeklyMaster[2])}}</div>
            <div class="p-col">${{formatAmount(weeklyMaster[3])}}</div>
            <div class="p-col">${{formatAmount(weeklyMaster[4])}}</div>
            <div class="p-col">${{formatAmount(weeklyMaster[5])}}</div>
            <div class="p-col">${{formatAmount(weeklyMaster[6])}}</div>
          </div>
          <div class="p-grid">
            <div class="p-col">TIPS</div>
            <div class="p-col">${{formatAmount(weeklyTip[0])}}</div>
            <div class="p-col">${{formatAmount(weeklyTip[1])}}</div>
            <div class="p-col">${{formatAmount(weeklyTip[2])}}</div>
            <div class="p-col">${{formatAmount(weeklyTip[3])}}</div>
            <div class="p-col">${{formatAmount(weeklyTip[4])}}</div>
            <div class="p-col">${{formatAmount(weeklyTip[5])}}</div>
            <div class="p-col">${{formatAmount(weeklyTip[6])}}</div>
          </div>
          <div class="p-grid">
            <div class="p-col">VISA</div>
            <div class="p-col">${{formatAmount(weeklyVisa[0])}}</div>
            <div class="p-col">${{formatAmount(weeklyVisa[1])}}</div>
            <div class="p-col">${{formatAmount(weeklyVisa[2])}}</div>
            <div class="p-col">${{formatAmount(weeklyVisa[3])}}</div>
            <div class="p-col">${{formatAmount(weeklyVisa[4])}}</div>
            <div class="p-col">${{formatAmount(weeklyVisa[5])}}</div>
            <div class="p-col">${{formatAmount(weeklyVisa[6])}}</div>
          </div>
          <div class="p-grid p-mt-2 " style="background-color:#004c97; color:#fff;">
            <div class="p-col">TOTAL PAYMENTS</div>
            <div class="p-col">${{formatAmount(sumSales[0])}}</div>
            <div class="p-col">${{formatAmount(sumSales[1])}}</div>
            <div class="p-col">${{formatAmount(sumSales[2])}}</div>
            <div class="p-col">${{formatAmount(sumSales[3])}}</div>
            <div class="p-col">${{formatAmount(sumSales[4])}}</div>
            <div class="p-col">${{formatAmount(sumSales[5])}}</div>
            <div class="p-col">${{formatAmount(sumSales[6])}}</div>
          </div>
          <div class="p-grid p-mt-2">
            <div class="p-col br">Amount Deposited</div>
            <div class="p-col br">$</div>
            <div class="p-col br">$</div>
            <div class="p-col br">$</div>
            <div class="p-col br">$</div>
            <div class="p-col br">$</div>
            <div class="p-col br">$</div>
            <div class="p-col br">$</div>
          </div>
          <div class="p-grid">
            <div class="p-col br">Date Deposited</div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
          </div>
           <div class="p-grid">
            <div class="p-col br">Associate initials</div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
          </div>
           <div class="p-grid">
            <div class="p-col br">Remarks</div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
          </div>
           <div class="p-grid">
            <div class="p-col br">Deposit Slips</div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
          </div>
           <div class="p-grid">
            <div class="p-col br">Turbo Slips</div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
          </div>
           <div class="p-grid">
            <div class="p-col br">Turbo Statements</div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
          </div>
           <div class="p-grid">
            <div class="p-col br">Date Deposited</div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
          </div>
           <div class="p-grid">
            <div class="p-col br">Refunds</div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
          </div>
          <div class="p-grid">
            <div class="p-col br">Payout Slip</div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
            <div class="p-col br"></div>
          </div>
          <div class="p-grid">
            <div class="p-col br bb">Daily Cash Sale Summary</div>
            <div class="p-col br bb"></div>
            <div class="p-col br bb"></div>
            <div class="p-col br bb"></div>
            <div class="p-col br bb"></div>
            <div class="p-col br bb"></div>
            <div class="p-col br bb"></div>
            <div class="p-col br bb"></div>
          </div>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
           <i class="pi pi-search" style="font-size:1.2rem;"></i> {{ dialogTitle }}
          </h5>
        </template>
        <div class="p-grid">
            <div class="p-col">
              <label for="weekDate">Week Date</label>
              <input type="date" id="weekDate"  v-model="searchAssociate.weekDate" class="form-control">
            </div>
        </div>
        <div class="p-field p-pt-5">
            <label for="filterStore">Filter Branch</label>
            <Dropdown
                v-model="searchAssociate.filterBranch"
                :options="filterBranch"
                :filter="true"
                optionLabel="mall"
                optionValue="code"
            />
            
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Search"
            icon="pi pi-search"
            class="p-button-primary"
            @click="loadList"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
})

export default class TopPerformanceReport extends mixins(UtilityOptions) {
  private reportService;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Weekly Payments Report" },
  ];

  private searchAssociate = {
    id: "",
    weekDate: "",
    filterBranch: 0,
  };

  private filterDates = [];
  private weekDates ={};
  private dialogTitle;
  private filterBranch:any = [];

  private weeklyCash = [0,0,0,0,0,0,0];
  private weeklyAmex = [0,0,0,0,0,0,0];
  private weeklyVisa = [0,0,0,0,0,0,0];
  private weeklyMaster = [0,0,0,0,0,0,0];
  private weeklyDebit = [0,0,0,0,0,0,0];
  private weeklyDiscover = [0,0,0,0,0,0,0];
  private weeklyGift = [0,0,0,0,0,0,0];
  private weeklyTip = [0,0,0,0,0,0,0];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.dialogTitle = "Filter Payments";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.weeklyPaymentReport(this.searchAssociate).then((res) => {
      const data       = this.camelizeKeys(res);
      this.resultTitle     = data.resultTitle;
      this.weekDates       = data.weekDates;
      this.weeklyCash      = data.record.cash;
      this.weeklyAmex      = data.record.amex;
      this.weeklyDebit     = data.record.debit;
      this.weeklyDiscover  = data.record.discovery;
      this.weeklyGift      = data.record.giftCard;
      this.weeklyMaster    = data.record.master;
      this.weeklyTip       = data.record.tip;
      this.weeklyVisa      = data.record.visa;
      this.loading     = false;
    });
    this.productDialog   = false;
  }

  get sumSales()
  {
    let totalSales = [0,0,0,0,0,0,0];

    this.weeklyCash.forEach((e,i) => {
       let total = 0;
        total = total + e; 
        total = total + this.weeklyAmex[i]; 
        total = total + this.weeklyDebit[i]; 
        total = total + this.weeklyDiscover[i]; 
        total = total + this.weeklyGift[i]; 
        total = total + this.weeklyMaster[i]; 
        total = total + this.weeklyTip[i]; 
        total = total + this.weeklyVisa[i]; 
        totalSales[i] = total; 
    });

    return totalSales;
  }
}
</script>

<style scoped>
.br
{
  border-top:1px solid #ccc;
  border-right:1px solid #ccc;
}
.bb
{
  border-bottom:1px solid #ccc;
}
</style>