
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
})

export default class TopPerformanceReport extends mixins(UtilityOptions) {
  private reportService;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Weekly Payments Report" },
  ];

  private searchAssociate = {
    id: "",
    weekDate: "",
    filterBranch: 0,
  };

  private filterDates = [];
  private weekDates ={};
  private dialogTitle;
  private filterBranch:any = [];

  private weeklyCash = [0,0,0,0,0,0,0];
  private weeklyAmex = [0,0,0,0,0,0,0];
  private weeklyVisa = [0,0,0,0,0,0,0];
  private weeklyMaster = [0,0,0,0,0,0,0];
  private weeklyDebit = [0,0,0,0,0,0,0];
  private weeklyDiscover = [0,0,0,0,0,0,0];
  private weeklyGift = [0,0,0,0,0,0,0];
  private weeklyTip = [0,0,0,0,0,0,0];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.dialogTitle = "Filter Payments";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.weeklyPaymentReport(this.searchAssociate).then((res) => {
      const data       = this.camelizeKeys(res);
      this.resultTitle     = data.resultTitle;
      this.weekDates       = data.weekDates;
      this.weeklyCash      = data.record.cash;
      this.weeklyAmex      = data.record.amex;
      this.weeklyDebit     = data.record.debit;
      this.weeklyDiscover  = data.record.discovery;
      this.weeklyGift      = data.record.giftCard;
      this.weeklyMaster    = data.record.master;
      this.weeklyTip       = data.record.tip;
      this.weeklyVisa      = data.record.visa;
      this.loading     = false;
    });
    this.productDialog   = false;
  }

  get sumSales()
  {
    let totalSales = [0,0,0,0,0,0,0];

    this.weeklyCash.forEach((e,i) => {
       let total = 0;
        total = total + e; 
        total = total + this.weeklyAmex[i]; 
        total = total + this.weeklyDebit[i]; 
        total = total + this.weeklyDiscover[i]; 
        total = total + this.weeklyGift[i]; 
        total = total + this.weeklyMaster[i]; 
        total = total + this.weeklyTip[i]; 
        total = total + this.weeklyVisa[i]; 
        totalSales[i] = total; 
    });

    return totalSales;
  }
}
