
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
})

export default class TransactionReport extends mixins(UtilityOptions) {
  private lists  = [];
  private reportService;
  private counter = 0;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Transaction report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "None",
    reportType: "all",
    filterBranch: 0,
  };

  private filterDates = [];
  private dialogTitle;
  private filterBranch:any = [];

  private filterTypes = [
    { name: "All Receipts", value: "all" },
    { name: "Rack Receipts", value: "rack" },
    { name: "In Progress", value: "progress" },
    { name: "Ready Receipts", value: "ready" },
    { name: "Refund Receipts", value: "refund" },
    { name: "Buyback Receipts", value: "buyback" },
    { name: "Redo Receipts", value: "redo" },
    { name: "Pay Later Receipts", value: "paylater" },
    { name: "Split Receipts", value: "split" },
    { name: "Void Receipts", value: "void" },
    { name: "Corporate Receipts", value: "corporate" },
    { name: "Referral Receipts", value: "referral" },
    { name: "Walk in Receipts", value: "walk in" },
  ];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.dialogTitle = "Filter Transaction";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.transactionsReport(this.searchAssociate).then((res) => {
      const data       = this.camelizeKeys(res);
      this.resultTitle = data.resultTitle;
      this.lists       = data.record;
      console.log(this.lists);
      this.loading     = false;
    });
    this.productDialog   = false;
  }

  getBalanceAmt(balance)
  {
    balance = Number(balance);

    if(balance <= 0.02)
    {
      balance = 0;
    }

    return this.formatAmount(balance);
  }

  paymentMethod(method, status) {
    let type = "";
    if (status != "VOID") {
      if (method.length > 1) {
        type = "Split";
      } else if (method.length == 1) {
        type = method[0].paymentType;
      } else {
        type = "Pay Later";
      }
    } else {
      type = status;
    }

    return type;
  }
}
