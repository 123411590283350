<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-mx-2">
            <Button
              icon="pi pi-search"
              class="p-button-success px-4"
              @click="openDialog"
            />
          </div>
          <div class="">
            <Button
              icon="pi pi-print"
              class="p-button-primary px-4"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="m-2 mt-4 mb-4 p-text-center">
          <h5>Transactions Report</h5>
          <p>{{resultTitle}}</p>
      </div>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
        
          <Column header="Date/Time" >
            <template #body="slotProps">
               {{ formatDate(slotProps.data.date)}} /  {{ formatTime(slotProps.data.timeEntrance)}}
            </template>
          </Column>
          <Column header="Customer Name"  >
            <template #body="slotProps">
               {{slotProps.data.firstName}} {{slotProps.data.lastName}}   ({{slotProps.data.cusId}})
            </template>
          </Column>
          <Column header="Associate ID">
            <template #body="slotProps">
               {{slotProps.data.employeeId}}
            </template>
          </Column>
          <Column header="Inv No">
            <template #body="slotProps">
               {{slotProps.data.id}}
            </template>
          </Column>
          <Column header="Balance">
            <template #body="slotProps">
              $ {{getBalanceAmt(slotProps.data.totalBill - slotProps.data.totalPaid)}}
            </template>
          </Column>
          <Column header="Status">
            <template #body="slotProps">
               {{slotProps.data.status}}
            </template>
          </Column>
          <Column header="Due Date" style="width:100rem;">
            <template #body="slotProps">
               {{formatDate(slotProps.data.dueDate)}}
            </template>
          </Column>
          <Column header="Payment Method">
            <template #body="slotProps">
               {{ paymentMethod(slotProps.data.invoicePayment, slotProps.data.status) }}
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
           <i class="pi pi-search" style="font-size:1.2rem;"></i> {{ dialogTitle }}
          </h5>
        </template>
        <div class="p-field">
            <label for="filterStore">Filter Date</label>
            <Dropdown
              v-model="searchAssociate.filter"
              :options="filterDates"
              :filter="true"
              optionLabel="key"
              optionValue="value"
            />
        </div>
        <h5>OR</h5>
        <div class="p-grid">
            <div class="p-col">
              <label for="from">Date From</label>
              <input type="date" id="from"  v-model="searchAssociate.dateFrom" class="form-control">
            </div>
            <div class="p-col">
              <label for="to">Date To</label>
              <input type="date" id="to"  v-model="searchAssociate.dateTo" class="form-control">
            </div>
        </div>
        <div class="p-field">
          <label>Filter Types</label>
          <Dropdown
            v-model="searchAssociate.reportType"
            :options="filterTypes"
            optionLabel="name"
            optionValue="value"
          />
        </div>
        <div class="p-field p-pt-5">
            <label for="filterStore">Filter Branch</label>
            <Dropdown
                v-model="searchAssociate.filterBranch"
                :options="filterBranch"
                :filter="true"
                optionLabel="mall"
                optionValue="code"
            />
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Search"
            icon="pi pi-search"
            class="p-button-primary"
            @click="loadList"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
})

export default class TransactionReport extends mixins(UtilityOptions) {
  private lists  = [];
  private reportService;
  private counter = 0;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Transaction report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "None",
    reportType: "all",
    filterBranch: 0,
  };

  private filterDates = [];
  private dialogTitle;
  private filterBranch:any = [];

  private filterTypes = [
    { name: "All Receipts", value: "all" },
    { name: "Rack Receipts", value: "rack" },
    { name: "In Progress", value: "progress" },
    { name: "Ready Receipts", value: "ready" },
    { name: "Refund Receipts", value: "refund" },
    { name: "Buyback Receipts", value: "buyback" },
    { name: "Redo Receipts", value: "redo" },
    { name: "Pay Later Receipts", value: "paylater" },
    { name: "Split Receipts", value: "split" },
    { name: "Void Receipts", value: "void" },
    { name: "Corporate Receipts", value: "corporate" },
    { name: "Referral Receipts", value: "referral" },
    { name: "Walk in Receipts", value: "walk in" },
  ];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.dialogTitle = "Filter Transaction";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.transactionsReport(this.searchAssociate).then((res) => {
      const data       = this.camelizeKeys(res);
      this.resultTitle = data.resultTitle;
      this.lists       = data.record;
      console.log(this.lists);
      this.loading     = false;
    });
    this.productDialog   = false;
  }

  getBalanceAmt(balance)
  {
    balance = Number(balance);

    if(balance <= 0.02)
    {
      balance = 0;
    }

    return this.formatAmount(balance);
  }

  paymentMethod(method, status) {
    let type = "";
    if (status != "VOID") {
      if (method.length > 1) {
        type = "Split";
      } else if (method.length == 1) {
        type = method[0].paymentType;
      } else {
        type = "Pay Later";
      }
    } else {
      type = status;
    }

    return type;
  }
}
</script>