<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-mx-2">
            <Button
              icon="pi pi-search"
              class="p-button-success px-4"
              @click="openDialog"
            />
          </div>
          <div class="">
            <Button
              icon="pi pi-print"
              class="p-button-primary px-4"
              @click="openDialog"
            />
          </div>
        </template>
      </Toolbar>
      <div class="m-2 mt-4 mb-4 p-text-center">
          <h5>Tender Summary Report</h5>
          <p>{{resultTitle}} <br />
            <i> {{ getDateTime }} </i>
          </p>
      </div>
      <div class="p-mt-2">
        <div class="p-grid">
          <div class="p-col-2"></div>
          <div class="p-col-8">
              <div class="p-grid border-btm" style="background-color:#004C97; color:#fff;">
                <div class="p-col"></div>
                <div class="p-col">IN</div>
                <div class="p-col">OUT</div>
                <div class="p-col">RPT</div>
                <div class="p-col">DIFF</div>
              </div>
              <div class="p-grid border-btm p-mt-2">
                <div class="p-col"><h6> Cash </h6></div>
                <div class="p-col">$ {{formatAmount(totalInTotal[0])}}</div>
                <div class="p-col">$ {{formatAmount(totalOutTotal[0])}}</div>
                <div class="p-col">$0.00</div>
                <div class="p-col">$ {{formatAmount(totalInTotal[0]-totalOutTotal[0])}}</div>
              </div>
              <div class="p-grid border-btm p-mt-2">
                <div class="p-col"><h6> Visa </h6></div>
                <div class="p-col">$ {{formatAmount(totalInTotal[2])}}</div>
                <div class="p-col">$ {{formatAmount(totalOutTotal[2])}}</div>
                <div class="p-col">$0.00</div>
                <div class="p-col">$ {{formatAmount(totalInTotal[2]-totalOutTotal[2])}}</div>
              </div>
              <div class="p-grid border-btm p-mt-2">
                <div class="p-col"><h6> Debit </h6></div>
                <div class="p-col">$ {{formatAmount(totalInTotal[1])}}</div>
                <div class="p-col">$ {{formatAmount(totalOutTotal[1])}}</div>
                <div class="p-col">$0.00</div>
                <div class="p-col">$ {{formatAmount(totalInTotal[1]-totalOutTotal[1])}}</div>
              </div>
              <div class="p-grid border-btm p-mt-2">
                <div class="p-col"><h6> Master Card </h6></div>
                <div class="p-col">$ {{formatAmount(totalInTotal[3])}}</div>
                <div class="p-col">$ {{formatAmount(totalOutTotal[3])}}</div>
                <div class="p-col">$0.00</div>
                <div class="p-col">$ {{formatAmount(totalInTotal[3]-totalOutTotal[3])}}</div>
              </div>
              <div class="p-grid border-btm p-mt-2">
                <div class="p-col"><h6> Amex </h6></div>
                <div class="p-col">$ {{formatAmount(totalInTotal[4])}}</div>
                <div class="p-col">$ {{formatAmount(totalOutTotal[4])}}</div>
                <div class="p-col">$0.00</div>
                <div class="p-col">$ {{formatAmount(totalInTotal[4]-totalOutTotal[4])}}</div>
              </div>
              <div class="p-grid border-btm p-mt-2">
                <div class="p-col"><h6> Gift Card </h6></div>
                <div class="p-col">$ {{formatAmount(totalInTotal[5])}}</div>
                <div class="p-col">$ {{formatAmount(totalOutTotal[5])}}</div>
                <div class="p-col">$0.00</div>
                <div class="p-col">$ {{formatAmount(totalInTotal[5]-totalOutTotal[5])}}</div>
              </div>
              <div class="p-grid border-btm p-mt-2">
                <div class="p-col"><h6> Charge </h6></div>
                <div class="p-col">$ {{formatAmount(totalCharge)}}</div>
                <div class="p-col">$ 0.00</div>
                <div class="p-col">$0.00</div>
                <div class="p-col">$ 0.00</div>
              </div>
              <div class="p-grid border-btm p-mt-2">
                <div class="p-col"><h6> Credit Note </h6></div>
                <div class="p-col"></div>
                <div class="p-col">$ {{formatAmount(creditNote)}}</div>
                <div class="p-col">$0.00</div>
                <div class="p-col">$0.00</div>
              </div>
          </div>
          <div class="p-col-2"></div>
        </div>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '50vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
           <i class="pi pi-search" style="font-size:1.2rem;"></i> {{ dialogTitle }}
          </h5>
        </template>
        <div class="p-field">
            <label for="filterStore">Filter Date</label>
            <Dropdown
              v-model="searchAssociate.filter"
              :options="filterDates"
              :filter="true"
              optionLabel="key"
              optionValue="value"
            />
        </div>
        <h5>OR</h5>
        <div class="p-grid">
            <div class="p-col">
              <label for="from">Date From</label>
              <input type="date" id="from"  v-model="searchAssociate.dateFrom" class="form-control">
            </div>
            <div class="p-col">
              <label for="to">Date To</label>
              <input type="date" id="to"  v-model="searchAssociate.dateTo" class="form-control">
            </div>
        </div>
        <div class="p-field p-pt-5">
            <label for="filterStore">Filter Branch</label>
            <Dropdown
                v-model="searchAssociate.filterBranch"
                :options="filterBranch"
                :filter="true"
                optionLabel="mall"
                optionValue="code"
            />
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Search"
            icon="pi pi-search"
            class="p-button-primary"
            @click="loadList"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";
import moment from "moment";

interface ItemList {
  paymentType       : string; 		
  source            : string; 		
  transTotalAmount  : number; 		
}

@Options({
  components: {},
})

export default class TenderSummaryReport extends mixins(UtilityOptions) {
  private lists : ItemList []  = [];
  private reportService;
  private resultTitle = "";
  private creditNote = 0;
  private totalCharge = 0;
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Tender Summary report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "today",
    filterBranch: 0,
  };

  private filterDates = [];
  private dialogTitle;
  private filterBranch:any = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.dialogTitle = "Filter Payments";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.tenderSummary(this.searchAssociate).then((res) => {
      const data         = this.camelizeKeys(res);
      this.resultTitle   = data.resultTitle;
      this.lists         = data.record;
      this.totalCharge   = data.charge;
      this.creditNote    = data.balance;
      this.loading       = false;
    });
    this.productDialog   = false;
  }

  get totalInTotal()
  {
    let t = [0,0,0,0,0,0];
    this.lists.forEach(e => {
      if(e.source == 'invoice')
      {
        if(e.paymentType == 'Cash')
        {
          t[0] = t[0] + e.transTotalAmount; 
        }
        else if(e.paymentType == 'Debit')
        {
          t[1] = t[1] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Visa')
        {
          t[2] = t[2] + e.transTotalAmount;
        }
        else if(e.paymentType == 'MasterCard')
        {
          t[3] = t[3] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Amex')
        {
          t[4] = t[4] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Gift Card')
        {
          t[5] = t[5] + e.transTotalAmount;
        }
      }
    });
    return t;
  }
  
  get totalOutTotal()
  {
    let t = [0,0,0,0,0,0];
    this.lists.forEach(e => {
      if(e.source == 'refund' || e.source == 'buyback')
      {
        if(e.paymentType == 'Cash')
        {
          t[0] = t[0] + e.transTotalAmount; 
        }
        else if(e.paymentType == 'Debit')
        {
          t[1] = t[1] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Visa')
        {
          t[2] = t[2] + e.transTotalAmount;
        }
        else if(e.paymentType == 'MasterCard')
        {
          t[3] = t[3] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Amex')
        {
          t[4] = t[4] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Gift Card')
        {
          t[5] = t[5] + e.transTotalAmount;
        }
      }
    });
    return t;
  }


  get getDateTime() {
    return (
      "Printed at " +
      moment().format("DD-MMM-YYYY") +
      " / " +
      moment().format("hh:mm A")
    );
  }
}
</script>

<style scoped>
.border-btm
{
  border-bottom: 1px solid #ccc;
}

</style>