
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
})

export default class TopPerformanceReport extends mixins(UtilityOptions) {
  private lists  = [];
  private reportService;
  private counter = 0;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Sales Performance Report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "None",
    filterBranch: 0,
    empFilter: 0,
    condition: '>',
    dimension: 'Store',
    sort: 'DESC',
    limit: 10,
    amountValue: 0,
  };

  private dimensions = [
    {
      name: 'Store'
    },
    {
      name: 'Customer'
    },
    {
      name: 'Associate'
    },
    {
      name: 'Corporate'
    },
    {
      name: 'Referral'
    },
    {
      name: 'Item'
    },
    {
      name: 'Dry Cleaners'
    },
    {
      name: 'Service'
    },
    {
      name: 'Department'
    }
  ];

  private sortList = [
    {
      name: 'Sort by Ascending Order',
      value: 'ASC'
    },
    {
      name: 'Sort by Descending Order',
      value: 'DESC'
    }
  ];

  private conditions = [
    {
      name: '>',
      value:">"
    },
    {
      name: '<',
       value:"<"
    },
    {
      name: '<=',
      value:"<="
    },
    {
      name: '>=',
      value:">="
    },
    {
      name: '=',
      value:"="
    }
  ];

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch:any = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter Performance";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
      if(data.isAdmin == 'HQ')
      {
        this.filterBranch.push({
          'code': -1,
          'mall': 'All Stores'
        });

        this.searchAssociate.filterBranch = -1;
      }
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.performanceReport(this.searchAssociate).then((res) => {
      const data       = this.camelizeKeys(res);
      this.resultTitle = data.resultTitle;
      this.lists       = data.record;
      this.loading     = false;
    });
    this.productDialog   = false;
  }

  get storeHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'code', header: 'Store Code'},
      {field: 'mall', header: 'Store Name'},
      {field: 'telephone', header: 'Telephone No'},
      {field: 'contact', header: 'Contact No'},
      {field: 'province', header: 'Province Name'},
      {field: 'totalAmount', header: 'Total'},
    ];
  }
  
  get customerHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'customerName', header: 'Customer Name'},
      {field: 'customerEmail', header: 'Customer Email'},
      {field: 'customerTelephone', header: 'Customer Telephone'},
      {field: 'totalAmount', header: 'Total'},
    ];
  }
  
  get employeeHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'employeeId', header: 'Associate ID'},
      {field: 'employeeName', header: 'Associate Name'},
      {field: 'employeeEmail', header: 'Associate Email'},
      {field: 'employeeAddress', header: 'Address'},
      {field: 'userContact1', header: 'Associate Contact'},
      {field: 'jobClass', header: 'Job Class'},
      {field: 'totalAmount', header: 'Total Amount'},
    ];
  }
  
  get corporateHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'referalOrCorpId', header: 'Corporate ID'},
      {field: 'title', header: 'Corporate Name'},
      {field: 'accountType', header: 'Corporate Type'},
      {field: 'totalAmount', header: 'Total Amount'}
    ];
  }
  
  get referralHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'referralOrCorpId', header: 'Referral ID'},
      {field: 'referralName', header: 'Referral Name'},
      {field: 'totalAmount', header: 'Total Amount'}
    ];
  }
  
  get itemHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'service', header: 'Service'},
      {field: 'department', header: 'Department'},
      {field: 'itemName', header: 'Item Name'},
      {field: 'totalAmount', header: 'Total Amount'}
    ];
  }
  
  get departmentHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'department', header: 'Department'},
      {field: 'totalAmount', header: 'Total Amount'}
    ];
  }
  
  get serviceHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'service', header: 'Service'},
      {field: 'totalAmount', header: 'Total Amount'}
    ];
  }
  
  get dryCleanHeaders()
  {
    return  [
      {field: 'ctr',         header: 'SNO'},
      {field: 'code',  header: 'Store Code'},
      {field: 'mall',  header: 'Store Name'},
      {field: 'dcName',      header: 'Dry Cleaner Name'},
      {field: 'dcAddress',   header: 'Dry Cleaner Address'},
      {field: 'dcPhone',     header: 'Dry Cleaner Ph'},
      {field: 'service',     header: 'Service Name'},
      {field: 'totalAmount', header: 'Total Amount'}
    ];
  }

  get tableHeaders()
  {
    let headers:any = [];

    if(this.searchAssociate.dimension == 'Store')
    {
      headers = this.storeHeaders;
    }
    else if(this.searchAssociate.dimension == 'Customer')
    {
      headers = this.customerHeaders;
    }
    else if(this.searchAssociate.dimension == 'Associate')
    {
      headers = this.employeeHeaders;
    }
    else if(this.searchAssociate.dimension == 'Corporate')
    {
      headers = this.corporateHeaders;
    }
    else if(this.searchAssociate.dimension == 'Referral')
    {
      headers = this.referralHeaders;
    }
    else if(this.searchAssociate.dimension == 'Item')
    {
      headers = this.itemHeaders;
    }
    else if(this.searchAssociate.dimension == 'Department')
    {
      headers = this.departmentHeaders;
    }
    else if(this.searchAssociate.dimension == 'Service')
    {
      headers = this.serviceHeaders;
    }
    else if(this.searchAssociate.dimension == 'Dry Cleaners')
    {
      headers = this.dryCleanHeaders;
    }

    this.lists = [];

    return headers;
  }
}
