
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";
import moment from "moment";

interface ItemList {
  paymentType       : string; 		
  source            : string; 		
  transTotalAmount  : number; 		
}

@Options({
  components: {},
})

export default class TenderSummaryReport extends mixins(UtilityOptions) {
  private lists : ItemList []  = [];
  private reportService;
  private resultTitle = "";
  private creditNote = 0;
  private totalCharge = 0;
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Tender Summary report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "today",
    filterBranch: 0,
  };

  private filterDates = [];
  private dialogTitle;
  private filterBranch:any = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.dialogTitle = "Filter Payments";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.tenderSummary(this.searchAssociate).then((res) => {
      const data         = this.camelizeKeys(res);
      this.resultTitle   = data.resultTitle;
      this.lists         = data.record;
      this.totalCharge   = data.charge;
      this.creditNote    = data.balance;
      this.loading       = false;
    });
    this.productDialog   = false;
  }

  get totalInTotal()
  {
    let t = [0,0,0,0,0,0];
    this.lists.forEach(e => {
      if(e.source == 'invoice')
      {
        if(e.paymentType == 'Cash')
        {
          t[0] = t[0] + e.transTotalAmount; 
        }
        else if(e.paymentType == 'Debit')
        {
          t[1] = t[1] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Visa')
        {
          t[2] = t[2] + e.transTotalAmount;
        }
        else if(e.paymentType == 'MasterCard')
        {
          t[3] = t[3] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Amex')
        {
          t[4] = t[4] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Gift Card')
        {
          t[5] = t[5] + e.transTotalAmount;
        }
      }
    });
    return t;
  }
  
  get totalOutTotal()
  {
    let t = [0,0,0,0,0,0];
    this.lists.forEach(e => {
      if(e.source == 'refund' || e.source == 'buyback')
      {
        if(e.paymentType == 'Cash')
        {
          t[0] = t[0] + e.transTotalAmount; 
        }
        else if(e.paymentType == 'Debit')
        {
          t[1] = t[1] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Visa')
        {
          t[2] = t[2] + e.transTotalAmount;
        }
        else if(e.paymentType == 'MasterCard')
        {
          t[3] = t[3] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Amex')
        {
          t[4] = t[4] + e.transTotalAmount;
        }
        else if(e.paymentType == 'Gift Card')
        {
          t[5] = t[5] + e.transTotalAmount;
        }
      }
    });
    return t;
  }


  get getDateTime() {
    return (
      "Printed at " +
      moment().format("DD-MMM-YYYY") +
      " / " +
      moment().format("hh:mm A")
    );
  }
}
